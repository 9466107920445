import { Component, OnInit } from '@angular/core';
import {BaseOverlayDialogComponent} from '../../base-overlay-dialog/base-overlay-dialog';

@Component({
  selector: 'app-order-book-faq-dialog',
  templateUrl: './order-book-faq-dialog.component.html',
  styleUrls: ['./order-book-faq-dialog.component.scss']
})
export class OrderBookFaqDialogComponent extends BaseOverlayDialogComponent implements OnInit {
  title: string;

  ngOnInit(): void {
  }

  initialize(
    title: string = null
  ) {
    this.title = title;
  }

}
