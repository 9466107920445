import { Component, OnInit } from '@angular/core';


export enum roomType {
  'velka' = 'velka',
  'mala' = 'mala',
  'galeria' = 'galeria',
  'zasadacka' = 'zasadacka',
};

@Component({
  selector: 'app-rent-room',
  templateUrl: './rent-room.component.html',
  styleUrls: ['./rent-room.component.scss']
})
export class RentRoomComponent implements OnInit {

  roomType = roomType;

  visibleGaleria = false;

  galeriaItems = [];

  constructor() { }

  ngOnInit() {
  }

  showGaleria(type: roomType) {

    const url = 'assets/img/custom/UK/miestnosti/' + type + '/';

    switch (type) {

      case roomType.velka:
        this.galeriaItems = [
          {
            src: url + '1.jpg',
          },
          {
            src: url + '2.jpg',
          },
          {
            src: url + '3.jpg',
          },
          {
            src: url + '4.jpg',
          },
          {
            src: url + '5.jpg',
          },
          {
            src: url + '6.jpg',
          },
          {
            src: url + '7.jpg',
          }
        ];
        break;
      case roomType.mala:
        this.galeriaItems = [
          {
            src: url + '1.jpg',
          },
          {
            src: url + '2.jpg',
          },
          {
            src: url + '3.jpg',
          }
        ];
        break;
      case roomType.galeria:
        this.galeriaItems = [
          {
            src: url + '1.jpg',
          },
          {
            src: url + '2.jpg',
          },
          {
            src: url + '3.jpg',
          },
          {
            src: url + '4.jpg',
          },
          {
            src: url + '5.jpg',
          },
          {
            src: url + '6.jpg',
          }
        ];
        break;
      case roomType.zasadacka:
        this.galeriaItems = [
          {
            src: url + '1.JPG',
          },
          {
            src: url + '2.JPG',
          },
          {
            src: url + '3.JPG',
          }
        ];
        break;
    }

    this.visibleGaleria = true;

  }

}
