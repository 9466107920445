import { NgModule } from '@angular/core';
import { BaseModalDialogComponent } from './base-modal-dialog.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {BsModalService} from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    CommonModule,
  ],
  declarations: [
    BaseModalDialogComponent,
  ],
  providers: [
    BsModalService
  ]
})

export class BaseModalDialogModule {}
