<div class="template-content">

  <app-header></app-header>

  <router-outlet></router-outlet>

  <app-footer></app-footer>

  <div class="loading-container" *ngIf="loading">

    <div class="loading-bg"></div>

    <div class="loading-icon">

      <div class="spinner">

        <i class="fa fa-spinner fa-spin fa-fw"></i>

      </div>

      <h1 class="loading-icon-description" *ngIf="loadingText">{{loadingText}}</h1>

    </div>

  </div>


</div>
