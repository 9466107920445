import {NgModule} from '@angular/core';
import {BaseOverlayDialogComponent} from './base-overlay-dialog/base-overlay-dialog';
import {OverlayDialogRootComponent} from './overlay-dialog-root';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ModalModule} from 'ngx-bootstrap/modal';
import { OverlayService } from './overlay-service';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { ThesisRegisterFaqDialogComponent } from './faq/thesis-register-faq-dialog/thesis-register-faq-dialog.component';
import { BaseDialogTemplateComponent } from './base-dialog-template/base-dialog-template.component';
import { IsbnRegisterFaqDialogComponent } from './faq/isbn-register-faq-dialog/isbn-register-faq-dialog.component';
import { ArticleRegisterFaqDialogComponent } from './faq/article-register-faq-dialog/article-register-faq-dialog.component';
import { OrderBookFaqDialogComponent } from './faq/order-book-faq-dialog/order-book-faq-dialog.component';
import { BorrowTerminsFaqDialogComponent } from './faq/borrow-termins-faq-dialog/borrow-termins-faq-dialog.component';

@NgModule({
  declarations: [
    BaseOverlayDialogComponent,
    OverlayDialogRootComponent,
    ThesisRegisterFaqDialogComponent,
    BaseDialogTemplateComponent,
    IsbnRegisterFaqDialogComponent,
    ArticleRegisterFaqDialogComponent,
    OrderBookFaqDialogComponent,
    BorrowTerminsFaqDialogComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ModalModule,
  ],
  exports: [
    OverlayDialogRootComponent,
    BaseOverlayDialogComponent,
  ],
  providers: [
    OverlayService
  ]
})

export class OverlayDialogModule {
}
