import {Component, ElementRef, OnInit} from '@angular/core';
import {PortfolioItemEnum} from '../../enums/portfolio-item.enum';
import {PortfolioCategoryEnum} from '../../enums/portfolio-category.enum';
import {Router} from '@angular/router';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  filters = [
    {
      title: 'Všetky',
      data: '*'
    },
    {
      title: 'Tlač',
      data: '.' + PortfolioCategoryEnum.tlac
    },
    {
      title: 'Scan',
      data: '.' + PortfolioCategoryEnum.scan
    },
    {
      title: 'Darčeky',
      data: '.' + PortfolioCategoryEnum.darceky
    },
    {
      title: 'Viazanie',
      data: '.' + PortfolioCategoryEnum.vazby
    }
  ];

  portfolio = [
    {
      category: [PortfolioCategoryEnum.darceky],
      image: '/assets/img/custom/UK/Portfolio/Fotoobraz/obraz3.jpg',
      routerLink: PortfolioItemEnum.fotoObraz,
      title: 'Fotoobrazy',
      description: 'Fotoobrazy rôznej veľkosti'
    },
    {
      category: [PortfolioCategoryEnum.tlac],
      image: '/assets/img/custom/UK/Portfolio/Plotrovanie/plotro3.jpg',
      routerLink: PortfolioItemEnum.plotrovanie,
      title: 'Plotrovanie',
      description: 'Vyrezávanie nápisov zo samolepiacich fólií'
    },
    {
      category: [PortfolioCategoryEnum.scan],
      image: '/assets/img/custom/UK/Portfolio/ScanRobot/scanRobot1.jpg',
      routerLink: PortfolioItemEnum.scanRobot,
      title: 'Scan robot',
      description: 'Efektívne digitalizácie kníh'
    },
    {
      category: [PortfolioCategoryEnum.tlac],
      image: '/assets/img/custom/UK/Portfolio/Xbaner/xBaner1.jpg',
      routerLink: PortfolioItemEnum.xBaner,
      title: 'X-Banery',
      description: 'Ľahký, elegantný a samostatne stojaci'
    },
    {
      category: [PortfolioCategoryEnum.darceky],
      image: '/assets/img/custom/UK/Portfolio/Tricko/tricko4.jpg',
      routerLink: PortfolioItemEnum.tshirt,
      title: 'Tričká',
      description: 'Tričká s vlastným motívom'
    },
    {
      category: [PortfolioCategoryEnum.tlac],
      image: '/assets/img/custom/UK/Portfolio/Rollup/rollup1.jpg',
      routerLink: PortfolioItemEnum.rollup,
      title: 'Rollup systémy',
      description: 'Produkt prezenčných tlačovín'
    },
    {
      category: [PortfolioCategoryEnum.tlac, PortfolioCategoryEnum.vazby],
      image: '/assets/img/custom/UK/Portfolio/V1/11.jpg',
      routerLink: PortfolioItemEnum.VazbaV1,
      title: 'Väzby V1',
      description: 'Mäkké spinkované väzby'
    },
    {
      category: [PortfolioCategoryEnum.tlac, PortfolioCategoryEnum.vazby],
      image: '/assets/img/custom/UK/Portfolio/V2/V2-2.jpg',
      routerLink: PortfolioItemEnum.VazbaV2,
      title: 'Väzby V2',
      description: 'Mäkké lepené väzby'
    },
    {
      category: [PortfolioCategoryEnum.tlac, PortfolioCategoryEnum.vazby],
      image: '/assets/img/custom/UK/Portfolio/V8/V8-3.jpg',
      routerLink: PortfolioItemEnum.VazbaV8,
      title: 'Väzby V8',
      description: 'Tvrdá väzba'
    },
    {
      category: [PortfolioCategoryEnum.vazby],
      image: '/assets/img/custom/UK/Portfolio/Obaly/obal.jpg',
      routerLink: PortfolioItemEnum.obaly,
      title: 'Obaly',
      description: 'Semišové obaly na diplomy'
    },
    {
      category: [PortfolioCategoryEnum.tlac],
      image: '/assets/img/custom/UK/Portfolio/CD/CD.jpg',
      routerLink: PortfolioItemEnum.cd,
      title: 'Potlač CD',
      description: 'Potlač a napaľovanie CD, DVD nosičov'
    },
    {
      category: [PortfolioCategoryEnum.tlac, PortfolioCategoryEnum.darceky],
      image: '/assets/img/custom/UK/Portfolio/Fotky/foto1.jpg',
      routerLink: PortfolioItemEnum.foto,
      title: 'Tlač fotiek',
      description: 'Tlač fotiek na fototlačiarni'
    },
    {
      category: [PortfolioCategoryEnum.tlac],
      image: '/assets/img/custom/UK/Portfolio/OneWayVision/one-way3.jpg',
      routerLink: PortfolioItemEnum.oneWayVision,
      title: 'OneWay Vision',
      description: 'Jednostranne priehľadná fólia na sklo'
    },
    {
      category: [PortfolioCategoryEnum.tlac, PortfolioCategoryEnum.vazby, PortfolioCategoryEnum.darceky],
      image: '/assets/img/custom/UK/Portfolio/Kalendare/kalendar3.jpg',
      routerLink: PortfolioItemEnum.calendars,
      title: 'Kalendáre',
      description: ''
    },
    {
      category: [PortfolioCategoryEnum.tlac],
      image: '/assets/img/custom/UK/Portfolio/Vizitky/vizitky3.jpg',
      routerLink: PortfolioItemEnum.businessCards,
      title: 'Vizitky',
      description: ''
    },
    {
      category: [PortfolioCategoryEnum.tlac],
      image: '/assets/img/custom/UK/Portfolio/Banner/baner1.jpg',
      routerLink: PortfolioItemEnum.banners,
      title: 'Banery',
      description: ''
    },
    {
      category: [PortfolioCategoryEnum.tlac, PortfolioCategoryEnum.darceky],
      image: '/assets/img/custom/UK/Portfolio/Samolepky/samolepka3.jpg',
      routerLink: PortfolioItemEnum.stickers,
      title: 'Samolepky',
      description: ''
    },
    {
      category: [PortfolioCategoryEnum.tlac],
      image: '/assets/img/custom/UK/Portfolio/Postery/poster2.jpg',
      routerLink: PortfolioItemEnum.posters,
      title: 'Postery',
      description: 'Tlač posterov rôznych formátov'
    },
    {
      category: [PortfolioCategoryEnum.tlac],
      image: '/assets/img/custom/UK/Portfolio/PvcTabule/PvcTabula.jpg',
      routerLink: PortfolioItemEnum.pvcTable,
      title: 'PVC tabule',
      description: 'Reklamné tabule'
    },
    {
      category: [PortfolioCategoryEnum.tlac, PortfolioCategoryEnum.vazby],
      image: '/assets/img/custom/UK/Portfolio/ViazanieZP/diplomkyspolu.jpg',
      routerLink: PortfolioItemEnum.zp,
      title: 'Viazanie záverečných prác',
      description: ''
    },
    {
      category: [PortfolioCategoryEnum.tlac],
      image: '/assets/img/custom/UK/Portfolio/Planografia/planografia.jpg',
      routerLink: PortfolioItemEnum.planografia,
      title: 'Planografia',
      description: 'Tlač technických výkresov'
    },
    {
      category: [PortfolioCategoryEnum.tlac],
      image: '/assets/img/custom/UK/Portfolio/InformacneLetaky/letak.jpg',
      routerLink: PortfolioItemEnum.bulletin,
      title: 'Informačné letáky',
      description: ''
    },
    {
      category: [PortfolioCategoryEnum.tlac, PortfolioCategoryEnum.darceky],
      image: '/assets/img/custom/UK/Portfolio/tasky/tasky1.jpg',
      routerLink: PortfolioItemEnum.bag,
      title: 'Reklamné tašky',
      description: 'Textilné tašky s potlačou'
    },
    {
      category: [PortfolioCategoryEnum.tlac],
      image: '/assets/img/custom/UK/Portfolio/Citylight/citylight2.jpg',
      routerLink: PortfolioItemEnum.cityLight,
      title: 'Citylighty',
      description: ''
    },
    {
      category: [PortfolioCategoryEnum.tlac, PortfolioCategoryEnum.vazby],
      image: '/assets/img/custom/UK/Portfolio/DrotenaVazba/hreben1.jpg',
      routerLink: PortfolioItemEnum.drotenaVazba,
      title: 'Drôtené väzby',
      description: ''
    },
    {
      category: [PortfolioCategoryEnum.tlac],
      image: '/assets/img/custom/UK/Portfolio/Oznamenia/pozvanka.jpg',
      routerLink: PortfolioItemEnum.card,
      title: 'Oznámenia, pozvánky',
      description: ''
    },
    {
      category: [PortfolioCategoryEnum.scan],
      image: '/assets/img/custom/UK/Portfolio/VelkoplosnyScan/sken.jpg',
      routerLink: PortfolioItemEnum.wideScan,
      title: 'Veľkoplošný scan',
      description: 'Scan technických výkresov'
    }
  ];

  loaded = false;

  constructor(
    private elementRef: ElementRef,
    protected router: Router
  ) {
  }

  ngOnInit() {
    this.appendCubePortfolioScript();
  }

  appendCubePortfolioScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'assets/js/plugins/cube-portfolio/cube-portfolio-4.js';

    this.elementRef.nativeElement.appendChild(script);

    setTimeout(() => {
      this.loaded = true;
    }, 200);

  }

  clickedPortfolioItem(portfolioItem) {
    if (!portfolioItem || !portfolioItem.routerLink) {
      return;
    }
    this.router.navigate([`/portfolio-detail/${portfolioItem.routerLink}`]);
  }

}
