import * as _ from 'lodash';

export class StringUtils {
    private static readonly REGEX__CONTAINS_ONLY_WHITESPACE: RegExp = new RegExp('^\s+$', 'i');

    public static extractNumericValueFromCSS(s: string): number {
        if (!s) {
            return 0;
        }

        const result = parseFloat(s);

        if (_.isFinite(result)) {
            return result;
        }

        return 0;
    }

    public static isNullOrEmpty(s: string): boolean {
        return _.isNil(s) || s === '';
    }

    public static isNullOrWhitespace(s: string): boolean {
        const nullOrEmpty: boolean = this.isNullOrEmpty(s);
        let onlyWhitespace = true;

        if (!nullOrEmpty) {
            onlyWhitespace = StringUtils.REGEX__CONTAINS_ONLY_WHITESPACE.test(s);
        }

        return nullOrEmpty || onlyWhitespace;
    }

    public static mergeRoutes(baseRoute: string, ...routes: string[]): string {
        if (StringUtils.isNullOrEmpty(baseRoute)) {
            return undefined;
        }

        const processedBaseRoute = _.endsWith(baseRoute, '/') ? baseRoute.substr(0, baseRoute.length - 1) : baseRoute;
        const processedRoutes = _.map(routes, route => {
            if (StringUtils.isNullOrEmpty(route)) {
                return '';
            }

            const processedRoute = _.endsWith(route, '/') ? route.substr(0, route.length - 1) : route;

            return _.startsWith(processedRoute, '/') ? processedRoute : `/${processedRoute}`;
        });

        let result = processedBaseRoute;

        _.forEach(processedRoutes, route => {
            if (StringUtils.isNullOrEmpty(route)) {
                return;
            }

            result = result.concat(route);
        });

        return result;
    }

    public static normalizeStringForSearch(
        s: string,
        caseConversion: 'upper' | 'lower' | 'none' = 'none',
        accentInsensitive: boolean = true,
        normalizationForm: string = 'NFD'
    ): string {
        if (_.isNil(s)) {
            return undefined;
        }

        let result = s.normalize(normalizationForm);

        if (accentInsensitive) {
            result = result.replace(/[\u0300-\u036f]/g, '');
        }

        switch (caseConversion) {
            case 'upper':
                result = _.toUpper(result);
                break;

            case 'lower':
                result = _.toLower(result);
                break;

            default:
                break;
        }

        return result;
    }
}
