<div class="headline"><h2>Nenašli ste odpoveď na vašu otázku? Napíšte nám.</h2></div>

<div class="row main-check margin-bottom-30">
  <div class="col-xs-12">

    <form>
      <label>Email <span class="color-red">*</span></label>
      <div class="row sky-space-20">
        <div class="col-md-11 col-md-offset-0">
          <input type="text" class="form-control" name="email" [(ngModel)]="form.email" email="true" #email required>
        </div>
      </div>
      <br/>
      <label>Správa <span class="color-red">*</span></label>
      <div class="row sky-space-20 ">
        <div class="col-md-11 col-md-offset-0">
          <div class="state-error">
            <textarea rows="5" class="form-control" name="message" [(ngModel)]="form.message" required #message></textarea>
          </div>
        </div>
      </div>
      <div class="margin-top-20">
        <button
          type="submit"
          class="btn-u"
          value="submit"
          [disabled]="!email.validity.valid || !message.validity.valid"
          (click)="sendMessage($event)"
        >
          Odoslať správu
        </button>
      </div>
    </form>
  </div>
</div>
<!-- Contact us -->
