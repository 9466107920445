import {Component, OnInit, ViewChild} from '@angular/core';
import {HeaderComponent} from '../../components/header/header.component';
import {AppCommunicationService} from '../../services/app-communication.service';

@Component({
  selector: 'app-template-content',
  templateUrl: './template-content.component.html',
  styleUrls: ['./template-content.component.scss']
})
export class TemplateContentComponent implements OnInit {
  loading = false;
  loadingText: string;

  @ViewChild(HeaderComponent) headerComponent: HeaderComponent;
  constructor(
    protected appCommunicationService: AppCommunicationService
  ) {
    setTimeout(() => {

      this.appCommunicationService
        .loadingAnimation
        .subscribe(value => {
          setTimeout(() => {
            this.loading = value;
          }, 50);
        });

      this.appCommunicationService
        .loadingAnimationText
        .subscribe(val => {
          this.loadingText = val;
        });

    }, 50);
  }

  ngOnInit() {}

}
