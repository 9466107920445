import {Component, OnInit} from '@angular/core';
import {ContactUsService} from './contact-us.service';
import {first} from 'rxjs';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  form = {
    email: '',
    message: ''
  };

  constructor(
    private contactUsService: ContactUsService
  ) {
  }

  ngOnInit(): void {
  }

  sendMessage(e: Event) {
    e.preventDefault();

    if (this.form.email.length > 0 && this.form.message.length > 0) {
      this.contactUsService
        .sendFeedback(this.form.email, this.form.email, this.form.message)
        .pipe(first())
        .subscribe({
          next: () => {
            this.clearForm();
          },
          error: err => {
            console.log(err);
          }
        });
    }
  }

  clearForm() {
    this.form.email = '';
    this.form.message = '';
  }

}
