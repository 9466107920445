<div class="footer-v1">
  <div class="footer">
    <div class="container">
      <div class="row">

        <div class="col-md-3 md-margin-bottom-40">
          <a routerLink="news">
            <img
                 class="footer-logo"
                 [src]="infoSection?.ukLogo" alt="UK Logo">
          </a>
<!--          <a class="margin-left-15" href="http://www.tuke.sk/" target="_blank">-->
<!--            <img class="footer-logo"-->
<!--                 [src]="infoSection?.tukeLogo"-->
<!--                 alt="TUKE Logo">-->
<!--          </a>-->

          <p>
            {{infoSection?.description | i18n}}
          </p>

        </div>

        <div class="col-md-3 md-margin-bottom-40" *ngFor="let category of categories">
          <div class="headline"><h2>{{category?.title | i18n}}</h2></div>
          <ul class="list-unstyled link-list">
            <ng-container *ngFor="let categoryItem of category?.items">

              <li *ngIf="categoryItem?.type === 'modal'">
                <a data-toggle="modal"
                   (click)="openModal(categoryItem.data)"
                  href="#">
                  {{categoryItem?.title | i18n}}
                </a>
              </li>

              <li *ngIf="categoryItem?.type === 'link'">
                <a [href]="categoryItem?.data"
                   target="_blank">
                  {{categoryItem?.title}}</a>
                <i class="fa fa-angle-right"></i>
              </li>

            </ng-container>
          </ul>
        </div>

        <div class="col-md-3 map-img md-margin-bottom-40">
          <div class="headline"><h2>{{'Kontaktujte nás' | i18n}}</h2></div>
          <address class="md-margin-bottom-40">
            {{contactSection?.address1}} <br/>
            {{contactSection?.address2}} <br/>
            <i class="fa fa-phone"></i> {{contactSection?.phone}} <br/>
            <i class="fa fa-envelope"></i>
            <a style="color:white;" [href]="'mailto:' + contactSection?.mail" >
              {{contactSection?.mail}}
            </a><br/>
          </address>
        </div>
      </div>
    </div>
  </div>

  <div class="copyright">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <p>
          </p>
        </div>
        <!-- Social Links -->
        <div class="col-md-6">

          <ul class="footer-socials list-inline">

            <li>{{'Sociálne siete' | i18n}}</li>
            <li>
              <a href="http://www.facebook.com/Univerzitna.kniznica.TUKE/" class="tooltips" data-toggle="tooltip"
                 data-placement="top" title="Facebook">
                <i class="fa fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/libtuke/" class="tooltips" data-toggle="tooltip" data-placement="top"
                 title="Instagram">
                <i class="fa fa-instagram"></i>
              </a>
            </li>

            <li></li>
            <li>{{'Podporované prehliadače' | i18n}}</li>
            <li>
              <a href="https://www.google.com/intl/sk/chrome/browser/desktop/" class="tooltips" data-toggle="tooltip"
                 data-placement="top" title="Chrome">
                <i class="fa fa-chrome"></i>
              </a>
            </li>
            <li>
              <a href="https://www.mozilla.org/sk/firefox/new/" class="tooltips" data-toggle="tooltip"
                 data-placement="top" title="Firefox">
                <i class="fa fa-firefox"></i>
              </a>
            </li>
            <li>
              <a href="http://www.opera.com/sk/download" class="tooltips" data-toggle="tooltip" data-placement="top"
                 title="Opera">
                <i class="fa fa-opera"></i>
              </a>
            </li>
            <li>
              <a href="#" class="tooltips" data-toggle="tooltip" data-placement="top" title="Safari">
                <i class="fa fa-safari"></i>
              </a>
            </li>
            <li>
              <a href="#" class="tooltips" data-toggle="tooltip" data-placement="top" title="Edge">
                <i class="fa fa-edge"></i>
              </a>
            </li>
          </ul>
        </div>
        <!-- End Social Links -->
      </div>
    </div>
  </div><!--/copyright-->
</div>
<!--=== End Footer Version 1 ===-->
