export const contact = {
  title: 'Univerzitná knižnica TUKE',
  address: 'Němcovej 7, 042 00 Košice, Slovensko',
  mail: 'kniznica@tuke.sk',
  phone: '+421 55 602 7700',
  web: 'https://lib.tuke.sk',
  gps: '48.731475, 21.243905',
  gpsFull: '48°43\'53.3"N 21°14\'38.1"E',
  mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1315.8198656252237!2d21.242980916548436!3d48.731475283995245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473ee05f0ef31aad%3A0x66c341b6c3936cb8!2sUniverzitn%C3%A1+kni%C5%BEnica+Technickej+univerzity+v+Ko%C5%A1iciach!5e0!3m2!1ssk!2ssk!4v1479998038178'
};
