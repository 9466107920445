<div class="container content-sm">
  <div class="row">
    <div class="col-md-4 content-boxes-v6 md-margin-bottom-50" *ngFor="let item of items">
      <a [routerLink]="item.routerLink">
        <i class="rounded-x fa" [ngClass]="item.icon"></i>
      </a>
      <h1 class="title-v3-md text-uppercase margin-bottom-10">{{item.title | i18n}}</h1>
      <p>{{item.text | i18n}}</p>
    </div>
  </div>
</div>
