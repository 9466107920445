export const languages: LanguageModel[] = [
    {
        'key': 'sk',
        'alt': 'Slovensko',
        'title': 'Slovenčina'
    },
  {
    'key': 'en',
    'alt': 'Angličtina',
    'title': 'Angličtina'
  }

];

export interface LanguageModel {
  key: string;
  alt: string;
  title: string;
}
