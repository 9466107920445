import { Component, OnInit } from '@angular/core';
import {BaseOverlayDialogComponent} from '../../base-overlay-dialog/base-overlay-dialog';

@Component({
  selector: 'app-article-register-faq-dialog',
  templateUrl: './article-register-faq-dialog.component.html',
  styleUrls: ['./article-register-faq-dialog.component.scss']
})
export class ArticleRegisterFaqDialogComponent extends BaseOverlayDialogComponent implements OnInit {
  title: string;

  ngOnInit(): void {
  }

  initialize(
    title: string = null
  ) {
    this.title = title;
  }

}
