<!--=== Breadcrumbs ===-->
<div class="breadcrumbs">
  <div class="container">
    <h1 class="pull-left">{{'Aktuality' | i18n}}</h1>
  </div>
</div>

<div class="container content">
  <ul class="timeline-v1">

    <ng-container *ngFor="let image of news; let index = index;">

      <li [ngClass]="index % 2 === 0 ? '' : 'timeline-inverted'">

        <div class="timeline-badge primary">
          <i class="glyphicon glyphicon-record"
             [title]="'Príspevok pridaný ' + (image?.createdDatetime | date: 'dd.MM.YYYY')"></i>
        </div>

        <div class="timeline-panel">
          <div class="timeline-heading" *ngIf="image?.fileUrl">
            <a data-toggle="modal" href="#">
              <img class="img-responsive" [src]="image?.fileUrl" alt=""/>
            </a>
          </div>

          <!--          <div class="embed-responsive embed-responsive-16by9"-->
          <!--               *ngIf="image?.videoLink !== null && image?.videoLink !== ''">-->
          <!--            <iframe [src]="image?.videoLink" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>-->
          <!--          </div>-->

          <div class="timeline-body text-justify">
            <h2 class="font-light">{{lang === 'sk' ? image?.title : (image?.titleEn ? image?.titleEn : image?.title)}}</h2>
            <p [innerHTML]="lang === 'sk' ? image?.description : (image?.descriptionEn ? image?.descriptionEn : image?.description)"></p>
          </div>

          <div class="timeline-footer">
            <ul class="list-unstyled list-inline blog-info">
              <li>
                <i class="fa fa-clock-o"></i>
                {{image?.createdDatetime | date: 'dd.MM.YYYY'}}
              </li>
            </ul>
          </div>

        </div>

      </li>

      <li class="clearfix"></li>
    </ng-container>

  </ul>
</div>

