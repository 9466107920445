<div class="lang-switcher-pos">

  <div class="selected" (click)="clickedIcon()">

    <div class="icon-lang">
      <img [src]="'/assets/img/flags/' + currentLanguage?.key + '.svg'" [alt]="currentLanguage.title" />
    </div>

  </div>

  <div class="available-languages" [class.available-languages--hidden]="!expanded">

    <div class="lang" *ngFor="let lang of languages" (click)="selectLanguage(lang)">
      <img [src]="'/assets/img/flags/' + lang?.key + '.svg'" [alt]="lang.title" />

      {{lang?.title}}
    </div>

  </div>

</div>
