<div class="container content">

  <div class="title-box-v2">

    <h2>{{ 'Digitálna' | i18n }} <span class="color-green">{{ 'Knižnica' | i18n }}</span></h2>

    <p>
      {{'Hľadáš odbornú publikáciu alebo článok k tvojej téme? Skús si to vyhľadať medzi renomovanými zdrojmi na celom svete. Univerzitná knižnica TU Košice sprístupňuje elektronické informačné zdroje, ktoré umožňujú prístup k úplným textom článkov z medzinárodných vedeckých časopisov a zborníkov renomovaných vydavateľstiev, online knihy a príručky, citačné indexy a pod.' | i18n}}

      <br><br>

      {{'Pre pripojenie sa do digitálnej knižnice z domu využite vzdialený prístup. Návod na inštaláciu' | i18n}} <a href="https://uvt.tuke.sk/wps/portal/uv/sluzby/vzdialeny-pristup-vpn" target="_blank">{{'nájdete tu' | i18n}}</a>.
    </p>

  </div>

</div>

<div class="service-block-v4">
  <div class="container content">
    <div class="title-box-v2">
      <h2>{{ 'Online' | i18n }} <span class="color-green">{{ 'vyhľadávače' | i18n }}</span></h2>
    </div>
    <!-- Pricing Table-v8 -->
    <div class="row row-no-space margin-bottom-10">
      <div class="col-md-3 col-sm-6">
        <div class="pricing-table-v8 service-block-green sm-margin-bottom-30 md-margin-bottom-30">
          <div class="service-block ">
            <h2 class="heading-md">{{'Online katalóg UK' | i18n}}</h2>
            <br/>
            <p>{{'Hľadáš odbornú publikáciu alebo článok?' | i18n}}</p>
            <p>{{'Skús ju vyhľadať v online katalógu UK TUKE a následne si ju objednaj.' | i18n}}</p>
            <a href="http://opac.lib.tuke.sk/tukeopac?fn=main" target="_blank" class="btn-u btn-brd btn-u ">{{'Vyhľadávať teraz' | i18n}}</a>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="pricing-table-v8 service-block-orange sm-margin-bottom-30 md-margin-bottom-30">
          <div class="service-block ">
            <h2 class="heading-md">{{'Naviga' | i18n}}</h2>
            <br/>
            <p>{{'Hľadáš odbornú publikáciu alebo článok?' | i18n}}</p>
            <p>{{'Skús si to vyhľadať medzi renomovanými zdrojmi na celom svete.' | i18n}}</p>
            <a href="http://naviga.cz/cz/naviga/" target="_blank" class="btn-u btn-brd btn-u ">{{'Vyhľadávať teraz' | i18n}}</a>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="pricing-table-v8 service-block-red sm-margin-bottom-30">
          <div class="service-block ">
            <h2 class="heading-md">{{'Summon' | i18n}}</h2>
            <br/>
            <p>{{'Hľadáš odbornú publikáciu alebo článok?' | i18n}}</p>
            <p>{{'Skús si to vyhľadať medzi renomovanými zdrojmi na celom svete.' | i18n}}</p>
            <a href="http://tuke.summon.serialssolutions.com/" target="_blank" class="btn-u btn-brd btn-u ">{{'Vyhľadávať teraz' | i18n}}</a>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-6">
        <div class="pricing-table-v8 service-block-grey sm-margin-bottom-30">
          <div class="service-block ">
            <h2 class="heading-md">{{'InfoGate' | i18n}}</h2>
            <br/>
            <p>{{'Nenašiel si hľadanú knihu?' | i18n}}</p>

            <p>{{'Nájdi ju v celoslovenskom katalógu kníh v inej knižnici' | i18n}}</p>
            <br/><br/>
            <a href="https://www.infogate.sk/" target="_blank" class="btn-u btn-brd btn-u ">{{'Vyhľadávať teraz' | i18n}}</a>
          </div>
        </div>
      </div>

    </div>
    <!-- End Pricing Table-v8 -->
  </div>
</div>


<div class="parallax-bg parallaxBg1">
  <div class="container content parallax-about">
    <div class="title-box-v2">
      <h2>{{'Elektronické' | i18n}} <span class="color-green">{{'Informačné zdroje' | i18n}}</span></h2>
    </div>
    <!-- Elektronicke zdroje Content -->
    <div class="row job-content margin-bottom-30">
      <div class="col-md-3 col-sm-3 md-margin-bottom-40">
        <h3 class="heading-md"><strong>{{'Plnotextové databázy' | i18n}}</strong></h3>
        <ul class="list-unstyled categories">
          <li><a href="http://dl.acm.org/dl.cfm" target="_blank">ACM Digital Library</a></li>
          <li><a href="http://journals.aps.org/" target="_blank">American Physical Society Journals</a></li>
          <li><a
            href="http://search.ebscohost.com/Community.aspx?authtype=ip&ugt=723731763C0635473756355632253E7220E369D36613659362E323E330133603&stsug=Ah5c00vF1AJIRA1OaR8jLpvIT_aQSOEovvOjlN-ueN1ieEs97M5eOOEGu1wbxdgXGOw2ZNOdZt8I1e0mmwSesexj0HtyJHjhTQNpx1PqhmGkSmijVDozNzeMoUJJZBjQBdcDg4gpVjGF_8Hn9JBokpU_qZGui8_U2VHSOjrOgDN-pQA&IsAdminMobile=N&encid=22D731263C5635573716351632453C57302379C376C375C372C373C370C376C33013&selectServicesToken=Ax5DHPI3JvYhUL2sKk5jlAGPzGCmu6YfuXh07GaKraWzHXbctWEKts8jYZamPZfb-rtLDUu-hS2IxX0Sh3t3S5E0F9EhouUvdZCBa-ldSDZJVq6wXthmxBBdkfSKDUj_ohmpBRztE5mqpc0Xf6B1qtiRGj_d9NhFl5d0mWD2OMx2UW7v4uAiwvtXHCKRQlq8DDgslWTHqev8Sq3OEgRlD9C3Gu-V11rGE-6LN9JYW8-tQsIEj_YzSVz2k8L8tkgQliXQO9U2Hj8__zx7WojBNNceAycY4zyIgS0-ktuNpgTl4-VF"
            target="_blank">EBSCO</a></li>
          <li><a href="http://www.emeraldinsight.com/" target="_blank">Emerald</a></li>
          <li><a href="https://ieeexplore.ieee.org/Xplore/home.jsp" target="_blank">IEEE Xplore Digital Library</a></li>

          <li><a href="https://app.knovel.com" target="_blank">Knovel Library</a></li>
          <li><a href="http://search.proquest.com/index" target="_blank">ProQuest</a></li>
          <li><a href="http://www.sciencedirect.com/" target="_blank">Science Direct</a></li>
          <li><a href="http://link.springer.com/" target="_blank">Springer LINK</a></li>
          <li><a href="http://onlinelibrary.wiley.com/" target="_blank">Wiley-Blackwell</a></li>
          <li><a href="http://www.galepages.com/tuk" target="_blank">GALE CENGAGE</a></li>
        </ul>
      </div>

      <div class="col-md-3 col-sm-3 md-margin-bottom-40">
        <h3 class="heading-md"><strong>{{'Bibliografické databázy' | i18n}}</strong></h3>
        <ul class="list-unstyled categories">
          <li><a href="http://apps.webofknowledge.com/" target="_blank">Web of Science</a></li>
          <li><a href="https://www.scopus.com/home.uri" target="_blank">SCOPUS</a></li>
        </ul>
      </div>
      <div class="col-md-3 col-sm-3">
        <h3 class="heading-md"><strong>{{'Citačné databázy' | i18n}}</strong></h3>
        <ul class="list-unstyled categories">
          <li><a href="http://apps.webofknowledge.com/" target="_blank">Web of Science with Conference Proceedings</a>
          </li>
          <li><a href="https://www.scopus.com/home.uri" target="_blank">SCOPUS</a></li>
          <li><a href="http://apps.webofknowledge.com/" target="_blank">{{'Current Contents Connect - databáza karentovaných časopisov' | i18n}}</a></li>
          <li><a
            href="https://jcr.incites.thomsonreuters.com/JCRJournalHomeAction.action?SID=B1-pNKAw3Bhs8zYrqn3gNjpBO2x2FyDPx2BSJUf-18x2dzZKcVx2B7cKNYVa0lgrsEC0Qx3Dx3Dc2Wx2BRPBHx2B0meiCZFWUQwFQx3Dx3D-iyiHxxh55B2RtQWBj2LEuawx3Dx3D-1iOubBm4x2FSwJjjKtx2F7lAaQx3Dx3D&SrcApp=IC2LS&Init=Yes&wsid=X2JwTDEoXGg7n6vplYY"
            target="_blank">Journal Citation Reports</a></li>
          <li><a href="https://esi.clarivate.com" target="_blank">Essential Science Indicators</a></li>
        </ul>
      </div>
      <div class="col-md-3 col-sm-3 md-margin-bottom-40">
        <h3 class="heading-md"><strong>{{'Zdroje UK' | i18n}}</strong></h3>
        <ul class="list-unstyled categories">
          <li><a href="http://opac.lib.tuke.sk/" target="_blank">{{'Katalóg UK TUKE' | i18n}}</a></li>
          <li><a href="https://ebooks.lib.tuke.sk/login" target="_blank">eBooks</a></li>
          <li><a href="https://epc.portal.tuke.sk/" target="_blank">{{'Publikačná činnosť' | i18n}}</a>
            <small class="hex">&nbsp;(EPC TUKE)</small>
          </li>
          <li><a href="https://etd.portal.tuke.sk/" target="_blank">{{'Záverečné práce študentov' | i18n}}</a>
            <small class="hex">&nbsp;(ETD TUKE)</small>
          </li>
        </ul>
      </div>
    </div>

    <!-- 2riadok Elektronicke zdroje Content -->
    <div class="row job-content margin-bottom-40">
      <div class="col-md-3 col-sm-3 md-margin-bottom-40">
        <h3 class="heading-md"><strong>{{'Knižnice v SR' | i18n}}</strong></h3>
        <ul class="list-unstyled categories">
          <li><a href="http://www.snk.sk/sk/" target="_blank" title="Slovenská národná knižnica v Martine">SNK
            Martin</a></li>
          <li><a href="http://www.cvtisr.sk/" target="_blank" title="Centrum vedecko-technických informácií SR">CVTI Bratislava</a>
          </li>
          <li><a href="https://sek.euba.sk/" target="_blank" title="Slovenská ekonomická knižnica Bratislava">SEK
            Bratislava</a></li>
          <li><a href="https://sldk.tuzvo.sk/ " target="_blank"
                 title="Slovenská lesnícka a drevárska knižnica TU Zvolen">SLaDK TU Zvolen</a></li>
          <li><a href="http://www.slpk.sk/" target="_blank" title="Slovenská poľnohospodárska knižnica pri SPU v Nitre">SPK
            pri SPU Nitra</a></li>
          <li><a href="http://www.svkbb.eu/" target="_blank" title="Štátna vedecká knižnica v Banskej Bystrici">ŠVK
            Banská Bystrica</a></li>
          <li><a href="http://www.svkk.sk/" target="_blank" title="Štátna vedecká knižnica v Košiciach">ŠVK Košice</a>
          </li>

        </ul>
      </div>
      <div class="col-md-3 col-sm-3 md-margin-bottom-40">
        <h3 class="heading-md"><strong>&nbsp;</strong></h3>
        <ul class="list-unstyled categories">
          <li><a href="https://www.ulib.sk/sk/" target="_blank" title="Univerzitná knižnica v Bratislave">UK
            Bratislava</a></li>
          <li><a href="https://uniba.sk/o-univerzite/fakulty-a-dalsie-sucasti/akademicka-kniznica-uk/ " target="_blank" title="Univerzitná knižnica UK Bratislava">AK UK Bratislava</a>
          </li>
          <li><a href="http://www.pulib.sk/" target="_blank" title="Univerzitná knižnica PU Prešov">UK PU Prešov</a>
          </li>
          <li><a href="https://www.uk.ukf.sk/sk/" target="_blank" title="Univerzitná knižnica UKF v Nitre">UK UKF
            Nitra</a></li>
          <li><a href="http://www.upjs.sk/pracoviska/univerzitna-kniznica/" target="_blank"
                 title="Univerzitná knižnica UPJŠ Košice">UK UPJŠ Košice</a></li>
          <li><a href="http://www.library.umb.sk/" target="_blank" title="Univerzitná knižnica UMB Banská Bystrica">UK
            UMB Banská Bystrica</a></li>
          <li><a href="https://ukzu.uniza.sk/ " target="_blank" title="Univerzitná knižnica ŽU Žilina">UK
            ŽU Žilina</a></li>
        </ul>
      </div>
      <div class="col-md-3 col-sm-3">
        <h3 class="heading-md"><strong>{{'Knižnice v zahraničí' | i18n}}</strong></h3>
        <ul class="list-unstyled categories">
          <li><a href="https://knihovna.vse.cz/" target="_blank"
                 title="Centrum informačních a knihovníckych služeb Praha">CIKS Praha</a></li>
          <li><a href="http://www.knihovna.upol.cz/" target="_blank" title="Knihovna Univerzity Palackého Olomouc">KUP
            Olomouc</a></li>
          <li><a href="http://www.nkp.cz/" target="_blank" title="Národní knihovna České republiky Praha">NKČR Praha</a>
          </li>
          <li><a href="http://www.techlib.cz/cs/" target="_blank" title="Národní technická knihovna Praha">NTK Praha</a>
          </li>
          <li><a href="http://www.knihovna.zcu.cz/" target="_blank" title="Univerzitní knihovna ZČU v Plzni">UK ZČU
            Plzeň</a></li>
          <li><a href="http://knihovna.cvut.cz/cs/" target="_blank" title="Ústrední knihovna ČVUT Praha">UK ČVUT
            Praha</a></li>
          <li><a href="http://www.chemtk.cz/cs/" target="_blank" title="Ústřední knihovna VŠCHT Praha">UK VŠCHT
            Praha</a></li>
          <li><a href="http://www.bl.uk/" target="_blank">The British Library</a></li>
          <li><a href="http://www.theeuropeanlibrary.org/tel4/" target="_blank">The European Library</a></li>
          <li><a href="https://www.loc.gov/" target="_blank">Library of Congres</a></li>
        </ul>
      </div>
      <div class="col-md-3 col-sm-3 md-margin-bottom-40">
        <h3 class="heading-md"><strong>{{'Odporúčané zdroje' | i18n}}</strong></h3>
        <ul class="list-unstyled categories">
          <li><a href="assets/files/volne_dostupne_ezdroje.pdf" target="_blank" title="Voľne dostupné e-zdroje">{{'Voľne dostupné e-zdroje' | i18n}}</a>
          <li><a href="http://scientia.cvtisr.sk/" target="_blank" title="Vyhľadávací portál pre vedu a výskum">Scientia.sk </a>
          </li>
          <li><a href="http://rzblx1.uni-regensburg.de/ezeit/fl.phtml?bibid=TUKE&colors=7&lang=en" target="_blank"
                 title="Knižnica elektronických časopisov predplatených pre TUKE">{{'e-Časopisy - EZB' | i18n}} </a></li>
          <li><a href="http://www.infozdroje.sk/" target="_blank"
                 title="Albertina icome Bratislava, s.r.o., dodávateľ zahraničných elektronických informačných zdrojov pre vedu, výskum vzdelávanie a podnikanie">{{'Infozdroje' | i18n}} </a>
          </li>
          <li><a href="https://scholar.google.sk/" target="_blank"
                 title="Služba Google Scholar umožňuje jednoduchým spôsobom rozsiahle vyhľadávať vedeckú literatúru">Google
            Scholar </a></li>
          <li><a href="http://www.sciencedirect.com/scirus/" target="_blank"
                 title="Špecializovaný internetový vyhľadávač zameraný na vyhľadávanie vedeckých informácií">Scirus </a>
          </li>
        </ul>
      </div>

    </div>
    <!-- End Elektronicke zdroje Content -->
  </div>
</div>
<div class="container content">
  <div class="title-box-v2">
    <h2>{{'Podmienky využívania' | i18n}} <span class="color-green">{{'elektronických informačných zdrojov' | i18n}}</span></h2>
  </div>
  <div class="row margin-bottom-5">
    <div class="col-sm-12">
      <ul>
        <li>{{'Denný limit sťahovania je 25 plných textov (striktne sa to nekontroluje, ale kontroluje sa neštandardné sťahovanie)' | i18n}}
        </li>
        <li>{{'Je prísny zákaz používať automatické sťahovacie programy, resp. roboty pre uľahčenie alebo urýchlenie sťahovania. Táto anomália zanecháva výrazný podpis v logovacích súboroch a môže viesť až k zablokovaniu databázy pre celú univerzitu.' | i18n}}
        </li>
        <li>{{'Pristupujte k databázam zodpovedne.' | i18n}}</li>
        <li><a data-toggle="modal" data-target=".zmluvne-podmienky" href="#">{{'Všeobecné zmluvné podmienky CVTI SR pre prístup a využívanie elektronických informačných zdrojov v rámci projektu Národný informačný systém podpory výskumu a vývoja na Slovensku: prístup k elektronickým informačným zdrojom' | i18n}}</a></li>

      </ul>
    </div>
  </div>

</div>
<!--=== End Content Part ===-->


<!-- Large modal -->
<div class="modal fade zmluvne-podmienky" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="height:800px;">
      <div class="modal-header">
        <button aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
        <h4 id="myLargeModalLabel2" class="modal-title">{{'Všeobecné zmluvné podmienky CVTI SR' | i18n}}</h4>
      </div>
      <div class="modal-body" style="height:93%;">

      </div>
    </div>
  </div>
</div>
