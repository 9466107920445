import {Component, OnInit} from '@angular/core';
import {FeedbackRelations} from './feedback-relations';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {

  form = {
    name: '',
    email: '',
    relation: '',
    message: ''
  };

  feedbackRelations = FeedbackRelations;

  constructor() {
  }

  ngOnInit(): void {
  }

  submit(e: Event) {
    e.preventDefault();
    console.log(this.form);
  }

}
