<div class="breadcrumbs">
  <div class="container">
    <h1 class="pull-left">Feedback formulár</h1>
  </div>
</div>

<div class="container content">

  <ng-container [ngSwitch]="currentForm">
    <app-acquisitions-form *ngSwitchCase="formTypes.acquisition"></app-acquisitions-form>

    <app-buy-document-request-form *ngSwitchCase="formTypes.buyDocument"></app-buy-document-request-form>

    <app-digital-copy-request-form *ngSwitchCase="formTypes.digitalCopyRequest"></app-digital-copy-request-form>

    <app-education-form *ngSwitchCase="formTypes.education"></app-education-form>

    <app-epc-correction-request-form *ngSwitchCase="formTypes.epcCorrectionRequest"></app-epc-correction-request-form>

    <app-feedback-form *ngSwitchCase="formTypes.feedback"></app-feedback-form>

    <app-mvs-books-form *ngSwitchCase="formTypes.mvsBooks"></app-mvs-books-form>

    <app-mvs-magazine-form *ngSwitchCase="formTypes.mvsMagazine"></app-mvs-magazine-form>

    <app-priority-request-form *ngSwitchCase="formTypes.priorityRequest"></app-priority-request-form>

    <app-search-form *ngSwitchCase="formTypes.searchRequest"></app-search-form>
  </ng-container>

</div>
