<base-dialog-template [dialogHidden]="dialogHidden" (onClose)="close()">

  <ng-container heading *ngIf="title">{{title}}</ng-container>

  <ol content>
    <li>
      Vyplniť formulár žiadosti o pridelenie ISBN, ktorý nájdete na stránke:
      <a title="" class="tooltip-test" style="color:darkred;" href="https://isbn.lib.tuke.sk/" data-original-title="Tooltip"><b>ISBN</b></a>.
    </li>
    <br>
    <li>
      Vyplniť požadované údaje – autor, názov dokumentu, podnázov dokumentu (ak existuje), druh dokumentu, forma -
      väzba (brožovaná, viazaná, CD ROM, DVD ROM, online), poradie vydania, náklad, počet strán, rok vydania, tematická skupina, jazyk publikácie,
      predpokladaný dátum doručenia povinných výtlačkov do UK, predpokladaná kategória publikačnej činnosti.
    </li>
    <br>
    <li>
      Ak ide o publikáciu v kategórii FAI - redakčné a zostavovateľské práce (bibliografie, encyklopédie, katalógy, slovníky, zborníky,...),
      je potrebné uviesť editora/zostavovateľa.
    </li>
    <br>
    <li>
      Ak autor chce publikáciu zaevidovať do databázy EPC, publikácie kategórií AAA, AAB, ABA, ABB, ABC, ABD musia byť vydané v náklade
      minimálne 100 kusov. Publikácie ostatných kategórií musia byť vydané v náklade minimálne 50 kusov.
    </li>
    <br>
    <li>
      Vyplnený formulár žiadosti o pridelenie ISBN odoslať elektronicky, do 3 pracovných dní UK pridelí žiadateľovi emailom ISBN..
    </li>
    <br>
    <li>
      Po pridelení ISBN je potrebné v požadovanom čase priniesť povinné výtlačky do UK.
    </li>
  </ol>

</base-dialog-template>
