import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemIndex'
})
export class ItemIndexPipe implements PipeTransform {

  transform(item: any, array: any[]): number | null {

    const i = array.indexOf(item);

    return i;

  }

}
