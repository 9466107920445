import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buy-document-request-form',
  templateUrl: './buy-document-request-form.component.html',
  styleUrls: ['./buy-document-request-form.component.scss']
})
export class BuyDocumentRequestFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
