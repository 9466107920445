<div class="breadcrumbs">
  <div class="container">
    <h1 class="pull-left">FAQ</h1>
  </div>
</div>
<div class="container content faq-page">

  <div class="row">

    <div class="col-md-6 compensate-padding">

      <div class="tab-v1">
        <ul class="nav nav-tabs margin-bottom-20">

          <li *ngFor="let system of faqSystems; let i = index" [class.active]="currentSystem === system"
              (click)="changeFaqSystem(system)">
            <a data-toggle="tab" [href]="'#' + system">{{system | i18n}}</a></li>
        </ul>

        <ng-container *ngIf="items.length > 0; else noData">
          <p-accordion>
            <p-accordionTab *ngFor="let item of items; let index = index" [id]="index" [(selected)]="accordionTabStates[index]">

              <ng-template pTemplate="header">
                <span class="item-header-question">
                  {{item?.question}}

                  <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, index)"></i>

                </span>
              </ng-template>

              <ng-template pTemplate="content">
                <span [innerHTML]="item?.answer"></span>
              </ng-template>

            </p-accordionTab>
          </p-accordion>
        </ng-container>

        <ng-template #noData>
          {{'Žiadne dáta' | i18n}}
        </ng-template>
      </div>
    </div>

    <div class="col-md-6">
      <!-- Contact us -->
      <app-contact-us></app-contact-us>
    </div>

  </div>

</div>


