import {NgModule} from '@angular/core';

import {I18nPipe} from './i18n.pipe';
import {I18nService} from './i18n.service';
import {CommonModule} from '@angular/common';
import {JsonApiService} from './json-api.service';
import {HttpClientModule} from '@angular/common/http';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

@NgModule({
  imports: [
    CommonModule,
    BsDropdownModule,
    HttpClientModule
  ],
  declarations: [
    I18nPipe
  ],
  exports: [I18nPipe],
  providers: [I18nService, JsonApiService]

})
export class I18nModule {}
