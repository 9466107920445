<div class="overlay"
     *ngIf="!dialogHidden"
>

  <div class="overlay__bg"></div>

  <div class="pos">

    <div class="overlay__dialog">

      <div class="overlay__dialog__heading">

        <div class="heading">

          <ng-content select="[heading]"></ng-content>

          <div class="close-button" (click)="close()">
            <i class="fa fa-times"></i>
          </div>
        </div>

      </div>

      <div class="overlay__dialog__bottom">

        <ng-content select="[content]"></ng-content>

      </div>
    </div>

  </div>

</div>
