import {Component, OnInit} from '@angular/core';
import {FormTypesEnum} from './form-types.enum';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {

  currentForm: FormTypesEnum;

  formTypes = FormTypesEnum;

  constructor(
    protected activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {

    this.activatedRoute
      .params
      .subscribe(v => {
        if (v.type) {
          this.currentForm = v.type as FormTypesEnum;
        }
      });

  }

}
