import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PDF_TYPES } from './pdf-types.enum';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class PdfService {
  constructor(private http: HttpClient) {
  }

  downloadPdf(url: string, type: PDF_TYPES = PDF_TYPES.external): Observable<ArrayBuffer> {
    const httpOptions = {};

    if (type === PDF_TYPES.external) {
      httpOptions['headers'] = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      });
    } else {
      httpOptions['headers'] = new HttpHeaders({
        'Content-Type': 'application/pdf',
        Accept: 'application/pdf'
      });
      httpOptions['responseType'] = 'ArrayBuffer';
    }

    const fileUrl = url;

    if (type === PDF_TYPES.external) {
      return this.http.get(url, httpOptions)
        .pipe(
          map(res => {
            return res as ArrayBuffer;
          })
        );
    } else {
      return this.http.get(`${fileUrl}`, httpOptions)
        .pipe(map((res: any) => res), catchError(e => {
          throw new Error(e);
        }));
    }
  }
}
