import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {I18nService, languages} from '../../i18n';
import {FormTypesEnum} from '../forms/form-types.enum';
import {getUKPortalsUrl, getUKServicesUrl} from '../../app.urls';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  logoUrl = '/assets/img/custom/UK/Logo/logo-sk.png';

  navigation = [
    {
      title: 'Digitálna knižnica',
      href: 'digitalLibrary'
    },
    {
      title: 'Aktuality',
      href: 'news'
    },
    {
      title: 'O nás',
      href: 'about'
    },
    {
      title: 'Portfólio',
      href: 'portfolio'
    }
  ];

  servicesDropdown = {
    title: 'Služby',
    categories: [
      {
        categoryTitle: 'Portál',
        categoryItems: [
          {
            text: 'Publikačná činnosť',
            icon: 'fa fa-university',
            fullTitle: 'Systém pre evidenciu publikačnej činnosti TUKE',
            type: 'link',
            link: getUKPortalsUrl('EPC')
          },
          {
            text: 'Záverečné práce',
            icon: 'fa fa-graduation-cap',
            fullTitle: 'Systém na registráciu záverečných prác',
            type: 'link',
            link: '/#/etd'
          },
          {
            text: 'Habilitačné práce',
            icon: 'fa fa-graduation-cap',
            fullTitle: 'Systém na registráciu habilitačných prác',
            type: 'link',
            link: getUKPortalsUrl('ETD')
          },
          {
            text: 'Žiadosť o ISBN',
            icon: 'fa fa-barcode',
            fullTitle: 'Žiadosť o ISBN',
            type: 'link',
            link: getUKPortalsUrl('ISBN')
          }
        ]
      },
      {
        categoryTitle: 'E-služby',
        categoryItems: [
          {
            text: 'MVS-Knihy formulár',
            icon: 'fa fa-list',
            fullTitle: '',
            type: 'link',
            link: getUKServicesUrl(FormTypesEnum.mvsBooks)
          },
          {
            text: 'MVS-Časopisy formulár',
            icon: 'fa fa-list',
            fullTitle: '',
            type: 'link',
            link: getUKServicesUrl(FormTypesEnum.mvsMagazine)
          },
          {
            text: 'Žiadosť o rešerš',
            icon: 'fa fa-pied-piper',
            fullTitle: '',
            type: 'link',
            link: getUKServicesUrl(FormTypesEnum.searchRequest)
          },
          {
            text: 'Feedback formulár',
            icon: 'fa fa-paper-plane',
            fullTitle: '',
            type: 'link',
            link: getUKServicesUrl(FormTypesEnum.feedback)
          },
          {
            text: 'Vzdelávanie a školenia prihláška',
            icon: 'fa fa-map-signs',
            fullTitle: '',
            type: 'link',
            link: getUKServicesUrl(FormTypesEnum.education)
          },
          {
            text: 'Akvizičné formuláre',
            icon: 'fa fa-pencil',
            fullTitle: '',
            type: 'link',
            link: getUKServicesUrl(FormTypesEnum.acquisition)
          },
          {
            text: 'Žiadosť o poskytnutie dig. kópií',
            icon: 'fa fa-copy',
            fullTitle: '',
            type: 'link',
            link: getUKServicesUrl(FormTypesEnum.digitalCopyRequest)
          },
          {
            text: 'Žiadosť o korekciu záznamu v EPC',
            icon: 'fa fa-bug',
            fullTitle: '',
            type: 'link',
            link: getUKServicesUrl(FormTypesEnum.epcCorrectionRequest)
          },
          {
            text: 'Návrh na zakúpenie dokumentov',
            icon: 'fa fa-book',
            fullTitle: '',
            type: 'link',
            link: getUKServicesUrl(FormTypesEnum.buyDocument)
          }
        ]
      },
      {
        categoryTitle: 'Katalógy',
        categoryItems: [
          {
            text: 'Online katalóg UK (OPAC)',
            icon: 'fa fa-book',
            fullTitle: 'Online katalóg Univerzitnej Knižnice TUKE',
            type: 'link',
            link: 'http://opac.lib.tuke.sk/tukeopac?fn=main'
          },
          {
            text: 'eBooks - TUKE',
            icon: 'fa fa-bookmark',
            fullTitle: 'Online repozitár elektornických kníh',
            type: 'link',
            link: 'http://ebooks.lib.tuke.sk/login'
          }
        ]
      },
      {
        categoryTitle: 'Vyhľadávače',
        categoryItems: [
          {
            text: 'Summon',
            icon: 'fa fa-search',
            fullTitle: 'Nájdi odbornú publikáciu medzi renomovanými zdrojmi na celom svete.',
            type: 'link',
            link: 'http://tuke.summon.serialssolutions.com/'
          },
          {
            text: 'Naviga',
            icon: 'fa fa-search',
            fullTitle: 'Nájdi odbornú publikáciu medzi renomovanými zdrojmi na celom svete.',
            type: 'link',
            link: 'http://naviga.cz/cz/naviga/'
          },
          {
            text: 'InfoGate',
            icon: 'fa fa-search',
            fullTitle: 'Nájdi publikáciu v celoslovenskom katalógu kníh v inej slovenskej knižnici.',
            type: 'link',
            link: 'https://www.infogate.sk/'
          }
        ]
      },
      {
        categoryTitle: 'Prenájom priestorov',
        categoryItems: [
          {
            text: 'Informácie',
            icon: 'fa fa-info',
            fullTitle: 'Informácie',
            type: 'link',
            link: '/#/rent-room'
          }
        ]
      }
    ]
  };

  public languages: Array<any>;
  public currentLanguage: any;

  @Output() navigationChange: EventEmitter<string> = new EventEmitter<string>();
  constructor(private i18n: I18nService) {
  }

  ngOnInit() {
    this.languages = languages;
    this.currentLanguage = this.i18n.currentLanguage;

    this.i18n.state.subscribe(() => {
      this.checkImages();
    });
  }

  checkImages() {
    this.logoUrl = `/assets/img/custom/UK/Logo/logo-${this.i18n.currentLanguage.key}.png`;
  }

  setLanguage(language) {
    this.currentLanguage = language;
    this.i18n.setLanguage(language);
  }

}
