import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-copy-request-form',
  templateUrl: './digital-copy-request-form.component.html',
  styleUrls: ['./digital-copy-request-form.component.scss']
})
export class DigitalCopyRequestFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
