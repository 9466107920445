import {BaseCarouselItemInterface} from '../../../models/base-carousel-item.interface';

export const carouselItems: BaseCarouselItemInterface[] = [
  {
    backgroundImageUrl: '/assets/img/custom/UK/Sliders2/1.jpg'
  },
  {
    backgroundImageUrl: '/assets/img/custom/UK/Sliders2/2.jpg'
  },
  {
    backgroundImageUrl: '/assets/img/custom/UK/Sliders2/3.jpg'
  },
  {
    backgroundImageUrl: '/assets/img/custom/UK/Sliders2/4.jpg'
  },
  {
    backgroundImageUrl: '/assets/img/custom/UK/Sliders2/5.jpg'
  },
  {
    backgroundImageUrl: '/assets/img/custom/UK/Sliders2/6.jpg'
  }
];
