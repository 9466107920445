<div class="container content">
  <div class="title-box-v2">
    <h2>Prenájom pre <span class="color-green">vašu akciu</span></h2>
    <p>
      Hľadáte priestory na realizáciu vášho podujatia? Univerzitná knižnica ponúka na prenájom viaceré priestory.
      Veríme, že si z našej ponuky vyberiete priestor, ktorý bude vyhovovať pre vašu akciu.
    </p>
  </div>
</div>

<div class="service-block-v4">
  <div class="container content">
    <div class="title-box-v2">
      <h2>Priestory <span class="color-green">v knižnici</span></h2>
    </div>
    <!-- Pricing Table-v8 -->
    <div class="row row-no-space margin-bottom-10 row-cols-same-height">
      <div class="col-md-3 col-sm-6 equal-height-column">
        <div class="pricing-table-v8 service-block-green sm-margin-bottom-30 md-margin-bottom-30">
          <div class="service-block ">
            <h2 class="heading-max">Veľká konferenčná miestnosť</h2>
            <br/>
            <p>Miestnosť s kapacitou pre <strong> 100 osôb </strong>a rozlohou <strong> 230 m2. </strong> Priestor sa
              nachádza na prízemí. K&nbsp;dispozicií projektor, plátno, wifi, ozvučovacia technika. </p>
            <a (click)="showGaleria(roomType.velka)" class="btn-u btn-brd btn-u fancybox" title="Veľká konferenčná miestnosť 1">
              Galeria
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 equal-height-column">
        <div class="pricing-table-v8 service-block-orange sm-margin-bottom-30 md-margin-bottom-30">
          <div class="service-block ">
            <h2 class="heading-max">Malá konferenčná miestnosť</h2>
            <br/>
            <p>Miestnosť s kapacitou pre <strong> 30 ľudí </strong>o rozlohe <strong> 83 m2. </strong> Priestor sa
              nachádza na prízemí. K&nbsp;dispozicií projektor, plátno, wifi. </p>
            <br/>
            <a (click)="showGaleria(roomType.mala)"
               class="btn-u btn-brd btn-u fancybox" title="Malá konferenčná miestnosť 1">
              Galeria
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 equal-height-column">
        <div class="pricing-table-v8 service-block-red sm-margin-bottom-30">
          <div class="service-block ">
            <h2 class="heading-max">Výstavná sieň</h2>
            <br/>
            <p>Otvorený priestor s rozlohou <strong> 380 m2. </strong>Priestor sa nachádza na prízemí. S možnosťou
              usporiadať výstavy, burzy, bankety, coffe breaky.</p>
            <br/>
            <a (click)="showGaleria(roomType.galeria)"
               class="btn-u btn-brd btn-u fancybox" title="Výstavná sieň 1">
              Galeria
            </a>
          </div>
        </div>
      </div>

      <div class="col-md-3 col-sm-6 equal-height-column">
        <div class="pricing-table-v8 service-block-grey sm-margin-bottom-30">
          <div class="service-block ">
            <h2 class="heading-max">Zasadačka</h2>
            <br/>
            <p>Miestnosť s kapacitou pre <strong> 30 ľudí </strong>o rozlohe <strong> 70 m2. </strong>Priestor sa
              nachádza na 6. poschodí. K dispozícii projektor, plátno, flipchart, wifi. </p>
            <br/>


            <a (click)="showGaleria(roomType.zasadacka)"
               class="btn-u btn-brd btn-u fancybox" title="Zasadačka 1">
              Galeria
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


<div class="parallax-bg parallaxBg1">
  <div class="container content parallax-about">
    <div class="title-box-v2">
      <h2>Napíšte <span class="color-green">nám</span></h2>
    </div>


    <div class="col-md-4 col-sm-6">
      <div class="team-v1">
        <div class="inner-team">
          <h3>Ing. Zuzana Rolík Krupašová</h3>
          <small class="color-green">Kontaktná osoba</small>

          <ul class="list-unstyled">
            <li><i class="fa fa-phone"></i> +421 55 602 7700</li>
            <li><i class="fa fa-envelope"></i> {{'zuzana.rolik.krupasova@tuke.sk'}}</li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</div>

<p-galleria [value]="galeriaItems"
            [containerStyle]="{ 'max-width': '850px' }"
            [circular]="true"
            [showItemNavigators]="true"
            [(visible)]="visibleGaleria" [fullScreen]="true">
  <ng-template pTemplate="item" let-item>
    <img [src]="item.src" style="width: 100%; display: block;"/>
  </ng-template>
</p-galleria>
