<div class="header">

  <div class="container">

    <div class="header-container">

      <a class="logo" routerLink="dashboard">
        <img [src]="logoUrl" class="logo-image" alt="Logo">
      </a>

      <div class="top-actions">

        <div class="lang-switcher-container">

          <a class="faq-btn" routerLink="/faq">FAQ</a>

                  <app-language-switcher></app-language-switcher>

        </div>

        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-responsive-collapse">
          <span class="sr-only">Toggle navigation</span>
          <span class="fa fa-bars"></span>
        </button>

      </div>

    </div>

  </div>

  <div class="collapse navbar-collapse mega-menu navbar-responsive-collapse">

    <div class="container">

      <ul class="nav navbar-nav">

        <li *ngFor="let navItem of navigation">
          <a [routerLink]="navItem?.href">
            {{navItem?.title | i18n}}
          </a>
        </li>

        <li class="dropdown mega-menu-fullwidth">
          <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown">
            {{servicesDropdown?.title | i18n}}
          </a>
          <ul class="dropdown-menu">
            <li>
              <div class="mega-menu-content disable-icons">
                <div class="container">
                  <div class="row equal-height">

                    <div class="col-md-3 equal-height-in" *ngFor="let category of servicesDropdown.categories">

                      <ul class="list-unstyled equal-height-list">
                        <li><h3 class="menu-kategorie">{{category?.categoryTitle}}</h3></li>

                        <ng-container *ngFor="let categoryItem of category?.categoryItems">
                          <li *ngIf="categoryItem?.type === 'link'"
                              [title]="categoryItem?.fullTitle | i18n">
                            <a [href]="categoryItem?.link" target="_blank">
                              <i [class]="categoryItem?.icon"></i> {{categoryItem?.text | i18n}}</a></li>

                          <li *ngIf="categoryItem?.type === 'routerLink'">
                            <a [routerLink]="categoryItem?.link">
                              <i [class]="categoryItem?.icon"></i>
                              {{categoryItem?.text | i18n}}
                            </a>
                          </li>
                        </ng-container>
                      </ul>

                    </div>

                  </div>
                </div>
              </div>
            </li>
          </ul>
        </li>

      </ul>

    </div>

  </div>

</div>
