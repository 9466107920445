import {Component} from '@angular/core';
import {getUKPortalsUrl} from '../../app.urls';
import {copyToClipboard} from '../../misc/copy-to-clipboard';

export enum sectionIdsEnum {
  'tema' = '1',
  'stiahnutSablonu' = '2',
  'zadanieZp' = '3',
  'udajeZp' = '4',
  'klucSlova' = '5',
  'predbKontrola' = '6',
  'pripravaUdajov' = '7',
  'registraciaEtd' = '8',
  'uzamknutie' = '9',
  'protokol' = '10',
  'tlac' = '11',
  'cennik' = '12',
  'crzp' = '13',
  'odovzdavanie' = '14',
  'archivacia' = '15',
  'prezentacie' = '16',
  'harmonogramOdovzdaniaZp' = 'harmonogramOdovzdaniaZp'
}

@Component({
  selector: 'app-etd-page',
  templateUrl: './etd-page.component.html',
  styleUrls: ['./etd-page.component.scss']
})
export class EtdPageComponent {

  sectionIdsEnum = sectionIdsEnum;

  currentYear = new Date().getFullYear();

  contentLeft = [
    {
      text: '1. Téma ZP', href: sectionIdsEnum.tema
    }, {
      text: '2. Stiahnutie šablóny ZP', href: sectionIdsEnum.stiahnutSablonu
    }, {
      text: '3. Zadanie ZP / Zadávací list', href: sectionIdsEnum.zadanieZp
    }, {
      text: '4. Údaje o ZP a odklad sprístupnenia', href: sectionIdsEnum.udajeZp
    }, {
      text: '5. Vyplnenie kľúčových slov a abstraktov v IS MAIS', href: sectionIdsEnum.klucSlova
    }, {
      text: '6. Predbežná kontrola originality', href: sectionIdsEnum.predbKontrola
    }, {
      text: '7. Príprava údajov a súborov na registráciu ZP', href: sectionIdsEnum.pripravaUdajov
    }, {
      text: '8. Registrácia súborov v IS ETD', href: sectionIdsEnum.registraciaEtd
    }
  ];

  contentRight = [
    {
      text: '9. Uzamknutie práce', href: sectionIdsEnum.uzamknutie
    }, {
      text: '10. Protokol o kontrole originality', href: sectionIdsEnum.protokol
    }, {
      text: '11. Objednávka tlače a viazania ZP', href: sectionIdsEnum.tlac
    }, {
      text: '12. Cenník viazania a tlače ZP v Copycentre', href: sectionIdsEnum.cennik
    }, {
      text: '13. Sprístupnenie v CRZP', href: sectionIdsEnum.crzp
    }, {
      text: '14. Odovzdávanie výtlačkov ZP', href: sectionIdsEnum.odovzdavanie
    }, {
      text: '15. Archivácia ZP v knižnici', href: sectionIdsEnum.archivacia
    }, {
      text: '16. Prezentácie zo školenia ETD', href: sectionIdsEnum.prezentacie
    },
    {
      text: '17. Harmonogram odovzdávania ZP' + ` ${this.currentYear}`, href: sectionIdsEnum.harmonogramOdovzdaniaZp
    }
  ];

  filesEnum = {
    'electronicTheses': 'electronicTheses',
    'quoteLiterature': 'quoteLiterature'
  };

  constructor() {

    setTimeout(() => {
      this.checkHashInUrl();
    }, 100);

  }

  checkHashInUrl() {

    const hash = window.location.hash.replace('#/etd#', '');

    if(hash) {
      const array = [...this.contentLeft, ...this.contentRight];

      const exists = array.find(s => s.href === hash);

      if (exists) {
        this.scrollTo(exists.href);
      }
    }
  }

  clickedEtdButton() {
    window.open(getUKPortalsUrl('ETD'), '_blank');
  }

  clickedOpacCrzp() {
    window.open('https://opac.crzp.sk', '_blank');
  }

  clickedPricelist() {
    window.open('/assets/files/cennik.pdf', '_blank');
  }

  clickedMais() {
    window.open('https://student.tuke.sk', '_blank');
  }

  clickedNiesomPlagiator() {
    window.open('https://niesomplagiator.sk/', '_blank');
  }

  clickedPdfCreator() {
    window.open('https://www.pdfforge.org/pdfcreator', '_blank');
  }

  clickedOpac() {
    window.open('https://opac.lib.tuke.sk', '_blank');
  }

  scrollTo(href: string) {
    const y = document.getElementById(href).getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y,
      behavior: 'smooth'
    });
  }

  downloadFile(type: string) {
    let fileUrl = '/assets/files/';

    switch (type) {
      case this.filesEnum.quoteLiterature:
        fileUrl += 'CIT.pdf';
        break;
      case this.filesEnum.electronicTheses:
        fileUrl += 'ETD_theses.pdf';
        break;
    }

    window.open(fileUrl, '_blank');
  }

  copyQuestionUrl(e: Event, id: any) {
    e.stopImmediatePropagation();
    const url = `${window.location.origin}/#/etd#${id}`;
    copyToClipboard(url);
  }
}
