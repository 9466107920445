<base-dialog-template [dialogHidden]="dialogHidden" (onClose)="close()">

  <ng-container heading *ngIf="title">{{title}}</ng-container>

  <ol content>
    <li>
      Autor vyplní elektronický formulár Nový záznam, ktorý je prístupný po prihlásení jedinečným loginom a heslom na
      <a title="" class="tooltip-test" style="color:darkred;" href="https://epc.lib.tuke.sk/Formular.aspx" target="_blank" data-original-title="Tooltip"><b>EPC formulár</b></a>.
    </li>
    <br>
    <li>
      V prípade, že chce autor registrovať ohlas, vyplní elektronický formulár Nový ohlas
      <a title="" class="tooltip-test" style="color:darkred;" href="https://epc.lib.tuke.sk/FormularOhlas.aspx" target="_blank" data-original-title="Tooltip"><b>EPC nový ohlas</b></a>.
    </li>
    <br>
    <li>
      Po odoslaní formuláru autor vytlačí sprievodku a prinesie podklady do UK na Príjem EPC, prízemie Univerzitnej knižnice.
    </li>
    <br>
    <li>
      V prípade, že autor pripojí podklady elektronicky alebo uvedie vo formulári internetový odkaz, kde je prístupný plný text
      dokumentu, stačí ak oznámi archívne číslo na Príjme EPC 055/602 kl.7718 alebo mailom na jednu z nasledujúcich adries {{'svetlana.vargova@tuke.sk'}},
      {{'zaneta.hronska@tuke.sk'}}.
    </li>
    <br>
    <li>
      Článok v konferenčnom zborníku – podklady na spracovanie:
      <ul>
        <li>Plný text príspevku vo formáte pdf (zhodný s tlačenou verziou)</li>
        <li>Obsah (strana, kde sa nachádza príspevok)</li>
        <li>Tiráž (strana dokumentu, kde sú uvedené vydavateľské údaje, ISBN, ISSN alebo DOI)</li>
        <li>Titulná strana, obálka zborníku, údaje o konferencii, miesto a dátum konania</li>
        <li>Strana, kde sú uvedení recenzenti, ak nie sú priamo pri príspevku</li>
        <li>V prípade, ak bol príspevok pozvaný, je nutné priložiť Pozvánku na meno alebo v obsahu </li>
      </ul>
      uvedené pozvaný príspevok (invited paper)
    </li>
    <br>
    <li>
      Článok v nekonferenčnom zborníku - podklady na spracovanie:
      <ul>
        <li>Plný text príspevku vo formáte pdf (zhodný s tlačenou verziou)</li>
        <li>Obsah (strana, kde sa nachádza príspevok)</li>
        <li>Tiráž (vydavateľské údaje, ISBN, ISSN alebo DOI)</li>
        <li>Titulná strana + obálka zborníku</li>
        <li>Strana, kde sú uvedení recenzenti, ak nie sú priamo pri príspevku</li>
      </ul>
    </li>
    <br>
    <li>
      Článok v časopise - podklady na spracovanie:
      <ul>
        <li>Plný text príspevku vo formáte pdf (zhodný s tlačenou verziou)</li>
        <li>Obsah (strana, kde sa nachádza príspevok, prvá strana obsahu, kde je uvedený ročník, číslo časopisu)</li>
        <li>Tiráž (vydavateľské údaje, ISSN alebo DOI)</li>
        <li>Obálka časopisu</li>
      </ul>
      V prípade, že je časopis karentovaný, musí byť uvedený v databáze Current Contents Connect, a to nielen časopis, ale aj daný článok: <br>
      <a title="" class="tooltip-test" style="color:darkred;" href="http://apps.webofknowledge.com/CCC_GeneralSearch_input.do?product=CCC&amp;SID=S25KpQG39OWXVypOkQu&amp;search_mode=GeneralSearch" target="_blank" data-original-title="Tooltip"><b>Current Contents Connect</b></a>.<br>
      V prípade, že je časopis indexovaný, musí byť uvedený v databáze Web of Science alebo Scopus, a to nielen časopis, ale aj daný článok:<br>
      <a title="" class="tooltip-test" style="color:darkred;" href="http://apps.webofknowledge.com/WOS_GeneralSearch_input.do?product=WOS&amp;search_mode=GeneralSearch&amp;SID=1EC3e9Tz1Q5g6ktZdNe&amp;preferencesSaved=https://www.scopus.com/" target="_blank" data-original-title="Tooltip"><b>Web of Science</b></a>.
    </li>
    <br>
    <li>
      Monografia, zborník, skriptá, učebné texty (ako celok) - podklady na spracovanie:
      <ul>
        <li>Plný text publikácie vo formáte pdf (zhodný s tlačenou verziou)</li>
      </ul>
      Je nutné doniesť do knižnice aj originál, pre potreby porovnania zhody elektronickej
      a tlačenej verzie.
    </li>
    <br>
    <li>
      Citácie, recenzie, ohlasy registrované v citačných indexoch Web of Science a Scopus (kategória 1 alebo 2) - podklady na spracovanie:
      <ul>
        <li>
          Je potrebné si overiť, či sa konkrétna citácia nachádza v databáze, až keď sa citácia v databáze nachádza, je možné ju zaregistrovať.
        </li>
        <li>Napísať internetový odkaz, kde sa daná citácia nachádza (WOS alebo Scopus).</li>
        <li>Ak sa v databáze nenachádza, je možné ju zaregistrovať ako citáciu v zahraničnej alebo domácej publikácii (kategória 3 alebo 4).</li>
      </ul>
      <a title="" class="tooltip-test" style="color:darkred;" href="http://apps.webofknowledge.com/WOS_CitedReferenceSearch_input.do?SID=1AlCNS5HcR3aIhcUMqY&amp;product=WOS&amp;search_mode=CitedReferenceSearch" target="_blank" data-original-title="Tooltip"><b>Web of Science → Cited Reference Search</b></a><br>
      <a title="" class="tooltip-test" style="color:darkred;" href="https://www.scopus.com/" target="_blank" data-original-title="Tooltip"><b>Scopus</b></a>
    </li>
    <br>
    <li>
      Citácie, recenzie, ohlasy v domácich a zahraničných publikáciách neregistrované v citačných indexoch - podklady na spracovanie:
      <ul>
        <li>Strana v príspevku s uvedenou citáciou (kto je citovaný, použitá literatúra)</li>
        <li>Prvá strana príspevku (kto cituje)</li>
        <li>Obsah (stačí strana, kde sa nachádza príspevok, v prípade časopisu prvá strana obsahu)</li>
        <li>Tiráž (vydavateľské údaje, ISBN, ISSN alebo DOI)</li>
        <li>Titulná strana, obálka zborníka (titulná strana časopisu)</li>
      </ul>
    </li>
  </ol>

</base-dialog-template>
