export enum FaqSystemsEnum {
  'etd' = 'etd',
  'isbn' = 'isbn',
  'epc' = 'epc',
  'ebook' = 'ebook',
  'onlinecatalog' = 'onlinecatalog'
}

export const FaqSystems = [
  FaqSystemsEnum.etd,
  FaqSystemsEnum.isbn,
  FaqSystemsEnum.epc,
  FaqSystemsEnum.ebook,
  FaqSystemsEnum.onlinecatalog
];
