import {
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {OverlayDialog} from '../overlay-dialog';
import {OverlayService} from '../overlay-service';
import {Subject} from 'rxjs';


@Component({
  selector: 'base-overlay-dialog',
  templateUrl: 'base-overlay-dialog.html'
})
export class BaseOverlayDialogComponent implements OverlayDialog {
  data: any;

  constructor(
    protected overlayService: OverlayService,
  ) {
    this.didHide = new Subject<any>();
  }

  public swipeText = '';

  public isDismissing = false;

  public dialogHidden = true;
  public dialogAnimated = false;
  public overlayHidden = true;
  public overlayAnimated = false;

  public didHide: Subject<any>;

  public result: any = null;

  @ViewChild('dialog', {static: false})
  public dialogElement: ElementRef;


  /* *** Swipe up to dismiss functionality *** */

  protected triggerPosition = 120;
  protected wheeling;
  protected wheeldelta = {
    y: 0
  };

  getHideDuration(): number {
    return 600;
  }

  getShowDuration(): number {
    return 1100;
  }

  hide() {

    this.dialogAnimated = true;
    this.overlayAnimated = true;

    setTimeout(
      () => {
        this.dialogHidden = true;
      },
      50
    );

    setTimeout(
      () => {
        this.overlayHidden = true;
      },
      250
    );

    setTimeout(
      () => {
        this.didHide.next(this.result);
      },
      this.getHideDuration() + 100
    );

  }

  show() {

    this.dialogHidden = true;
    this.overlayHidden = true;

    setTimeout(
      () => {
        this.dialogAnimated = true;
        this.overlayAnimated = true;
      },
      50
    );

    setTimeout(
      () => {
        this.overlayHidden = false;
      },
      100
    );

    setTimeout(
      () => {
        this.dialogHidden = false;
      },
      300
    );

    setTimeout(
      () => {
        this.dialogAnimated = false;
        this.overlayAnimated = false;
      },
      1000
    );
  }

  close(result: any = null) {
    if (!this.isDismissing) {
      this.isDismissing = true;
      this.result = result;
      this.overlayService.hide().then(
        () => {

        },
        (error) => {
          console.error(error);
          setTimeout(
            () => {
              this.overlayService.hide();
            },
            1000
          );
        }
      );
    }
  }

  onWheel(e: WheelEvent) {
    clearTimeout(this.wheeling);
    this.wheeling = setTimeout(() => {
      this.wheeling = undefined;

      if (this.wheeldelta.y >= this.triggerPosition) {
        this.close();
      } else {
        this.resetDialogPosition();
      }

      // reset wheeldelta
      this.wheeldelta.y = 0;
    }, 400);

    this.wheeldelta.y += (e.deltaY / 4);

    if (this.wheeldelta.y > 0) {
      const calculatedYPostition = `${this.wheeldelta.y}px`;
      this.dialogElement.nativeElement.style.transform = `translateY(${calculatedYPostition})`;
      this.dialogElement.nativeElement.style.opacity = 1 - (this.wheeldelta.y / 200);
    } else {
      this.dialogElement.nativeElement.style.transform = 'initial';
      this.dialogElement.nativeElement.style.opacity = 1;
    }
  }

  resetDialogPosition() {
    this.dialogElement.nativeElement.style.transform = 'initial';
    this.dialogElement.nativeElement.style.opacity = 1;
    this.wheeling = undefined;
    this.wheeldelta.y = 0;
  }

  didDismissBySwiping(result = null): void {
    this.close(result);
  }


}
