import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {TemplateContentComponent} from './views/template-content/template-content.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {HttpClientModule} from '@angular/common/http';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {OwlModule} from 'ngx-owl-carousel';
import {I18nModule} from './i18n/i18n.module';
import {DigitalLibraryComponent} from './components/digital-library/digital-library.component';
import {routing} from './app.routing';
import {NewsComponent} from './components/news/news.component';
import {AboutComponent} from './components/about/about.component';
import {PortfolioComponent} from './components/portfolio/portfolio.component';
import {LanguageSwitcherModule} from './components/language-switcher/language-switcher.module';
import {AppCommunicationService} from './services/app-communication.service';
import {PortfolioDetailComponent} from './components/portfolio-detail/portfolio-detail.component';
import {RentRoomComponent} from './components/rent-room/rent-room.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {FaqComponent} from './components/faq/faq.component';
import {BaseModalDialogModule} from './components/dialog/base-modal-dialog.module';
import {AccordionModule} from 'primeng/accordion';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OverlayDialogModule} from './components/overlay-dialog/overlay-dialog.module';
import {PdfViewerModule} from './components/pdf-viewer/pdf-viewer.module';
import { ContactUsComponent } from './components/faq/contact-us/contact-us.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FormsComponent } from './components/forms/forms.component';
import { SearchFormComponent } from './components/forms/search-form/search-form.component';
import { MvsBooksFormComponent } from './components/forms/mvs-books-form/mvs-books-form.component';
import { MvsMagazineFormComponent } from './components/forms/mvs-magazine-form/mvs-magazine-form.component';
import { FeedbackFormComponent } from './components/forms/feedback-form/feedback-form.component';
import { EducationFormComponent } from './components/forms/education-form/education-form.component';
import { AcquisitionsFormComponent } from './components/forms/acquisitions-form/acquisitions-form.component';
import { PriorityRequestFormComponent } from './components/forms/priority-request-form/priority-request-form.component';
import { DigitalCopyRequestFormComponent } from './components/forms/digital-copy-request-form/digital-copy-request-form.component';
import { EpcCorrectionRequestFormComponent } from './components/forms/epc-correction-request-form/epc-correction-request-form.component';
import { BuyDocumentRequestFormComponent } from './components/forms/buy-document-request-form/buy-document-request-form.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardBottomSectionComponent } from './components/dashboard/dashboard-bottom-section/dashboard-bottom-section.component';
import {CarouselModule} from 'primeng/carousel';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { PortfolioMk2Component } from './components/portfolio-mk2/portfolio-mk2.component';
import { EtdPageComponent } from './components/etd-page/etd-page.component';
import { ItemIndexPipe } from './components/portfolio-detail/item-index.pipe';
import {GalleriaModule} from 'primeng/galleria';

@NgModule({
  declarations: [
    AppComponent,
    TemplateContentComponent,
    HeaderComponent,
    FooterComponent,
    DigitalLibraryComponent,
    NewsComponent,
    AboutComponent,
    PortfolioComponent,
    PortfolioDetailComponent,
    RentRoomComponent,
    FaqComponent,
    ContactUsComponent,
    FormsComponent,
    SearchFormComponent,
    MvsBooksFormComponent,
    MvsMagazineFormComponent,
    FeedbackFormComponent,
    EducationFormComponent,
    AcquisitionsFormComponent,
    PriorityRequestFormComponent,
    DigitalCopyRequestFormComponent,
    EpcCorrectionRequestFormComponent,
    BuyDocumentRequestFormComponent,
    DashboardComponent,
    DashboardBottomSectionComponent,
    SafeUrlPipe,
    PortfolioMk2Component,
    EtdPageComponent,
    ItemIndexPipe
  ],
    imports: [
        BrowserModule,
        routing,
        HttpClientModule,
        OwlModule,
        I18nModule,
        BsDropdownModule.forRoot(),
        LanguageSwitcherModule,
        BaseModalDialogModule,
        AccordionModule,
        BrowserAnimationsModule,
        OverlayDialogModule,
        PdfViewerModule,
        FormsModule,
        ReactiveFormsModule,
        CarouselModule,
        GalleriaModule
    ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    AppCommunicationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
