export const openingHours = [
  {
    sectionTitle: 'Výpožičná služba',
    sectionData: [
      {
        day: 'Po:',
        hours: '09:00 - 14:00'
      },
      {
        day: 'Ut:',
        hours: '09:00 - 14:00'
      },
      {
        day: 'St:',
        hours: '09:00 - 14:00'
      },
      {
        day: 'Št:',
        hours: '09:00 - 14:00'
      },
      {
        day: 'Pi:',
        hours: '09:00 - 14:00'
      },
      {
        day: 'So:',
        hours: 'Zatvorené'
      },
      {
        day: 'Ne:',
        hours: 'Zatvorené'
      }
    ]
  },
  {
    sectionTitle: 'Študovňa',
    sectionData: [
      {
        day: 'Po:',
        hours: '07:00 - 18:00'
      },
      {
        day: 'Ut:',
        hours: '07:00 - 18:00'
      },
      {
        day: 'St:',
        hours: '07:00 - 18:00'
      },
      {
        day: 'Št:',
        hours: '07:00 - 18:00'
      },
      {
        day: 'Pi:',
        hours: '07:00 - 15:00'
      },
      {
        day: 'So:',
        hours: 'Zatvorené'
      },
      {
        day: 'Ne:',
        hours: 'Zatvorené'
      }
    ]
  },
  {
    sectionTitle: 'Príjem EPC',
    sectionData: [
      {
        day: 'Po:',
        hours: '08:00 - 15:00'
      },
      {
        day: 'Ut:',
        hours: '08:00 - 15:00'
      },
      {
        day: 'St:',
        hours: '08:00 - 15:00'
      },
      {
        day: 'Št:',
        hours: '08:00 - 15:00'
      },
      {
        day: 'Pi:',
        hours: '08:00 - 15:00'
      },
      {
        day: 'So:',
        hours: 'Zatvorené'
      },
      {
        day: 'Ne:',
        hours: 'Zatvorené'
      }
    ]
  },
  {
    sectionTitle: 'Copycentrum',
    sectionData: [
      {
        day: 'Po:',
        hours: '07:00 - 15:00'
      },
      {
        day: 'Ut:',
        hours: '07:00 - 15:00'
      },
      {
        day: 'St:',
        hours: '07:00 - 15:00'
      },
      {
        day: 'Št:',
        hours: '07:00 - 15:00'
      },
      {
        day: 'Pi:',
        hours: '07:00 - 15:00'
      },
      {
        day: 'So:',
        hours: 'Zatvorené'
      },
      {
        day: 'Ne:',
        hours: 'Zatvorené'
      }
    ]
  }
];
