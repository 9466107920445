import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {getBaseApiUrl} from '../../../app.urls';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(
    private http: HttpClient
  ) {
  }

  sendFeedback(email: string, fullName: string, comment: string): Observable<any> {
    return this.http
      .post(`${getBaseApiUrl('ALFRED')}/form/feedback/create`, {
        email, fullName, comment
      });
  }

}
