<base-dialog-template [dialogHidden]="dialogHidden" (onClose)="close()">

  <ng-container heading *ngIf="title">{{title}}</ng-container>

  <ol content>
    <li>
      Na stránke <a title="" class="tooltip-test" style="color:darkred;" href="https://etd.portal.tuke.sk"
                    data-original-title="Tooltip"><b>ETD</b></a>, kde v menu kliknite na šablóny na stiahnutie, je
      šablóna, v ktorej je nutné napísať záverečnú
      prácu (ZP).
    </li>
    <br>
    <li>
      Pri registrácii ZP študent postupuje podľa nasledujúcich krokov. Všetky chyby a ich riešenie sú
      popísané nižšie v tomto dokumente.
    </li>
    <br>
    <li>
      Po napísaní práce sa každý študent prihlási najprv do IS MAIS, kde doplní kľúčové slová
      a abstrakty v slovenskom a v anglickom jazyku. V prípade, že nemá v IS MAIS uvedeného
      oponenta, kontaktuje vedúceho ZP. Ak práca obsahuje údaje, ktoré nemôžu byť sprístupnené,
      vedúci práce nastaví v IS MAIS odkladnú lehotu v úrovni sprístupnenia (12, 24 alebo 36
      mesiacov).
    </li>
    <br>
    <li>
      Následne sa študent prihlási na portáli <a title="" class="tooltip-test" style="color:darkred;"
                                                 href="https://etd.portal.tuke.sk" data-original-title="Tooltip"><b>IS
      ETD</b></a>, kde
      v menu klikne na položku REGISTROVAŤ ZP
    </li>
    <br>
    <li>
      Študent vyberie štúdium, pre ktoré chce nahrávať prácu a po načítaní údajov z IS MAIS klikne
      na tlačítko Pokračovať.
    </li>
    <br>
    <li>
      Ďalším krokom je kontrola registračných údajov a doplnenie chýbajúcich údajov – počet príloh
      (okrem CD), dátum zadania a dátum obhajoby.
    </li>
    <br>
    <li>
      V ďalšom kroku študent nahrá finálnu elektronickú verziu ZP vo formáte pdf, potom finálnu
      elektronickú verziu ZP v natívnom formáte, to znamená vo formáte programu MS Office (.doc,
      .docx), Open Office (.ott, .odt), alebo LaTeX a pod. Následne sa nahrávajú prílohy ZP
      skomprimované vo formáte rar, zip alebo 7z. Ak je počet príloh 0, tak sa prejde na ďalší krok.
    </li>
    <br>
    <li>
      Ďalej nasleduje generovanie a tlač licenčnej zmluvy. Systém automaticky vygeneruje 2x
      licenčnú zmluvu. Tieto dokumenty študent prinesie do knižnice, kde pracovník knižnice potvrdí
      ich správnosť. Jedna licenčná zmluva ostáva autorovi, druhá sa archivuje na TUKE.
    </li>
    <br>
    <li>
      Posledným krokom je uzamknutie ZP pracovníkom knižnice a odoslanie do centrálneho
      registra ZP.
    </li>
    <br>
    <li>
      Linka na protokol o kontrole originality je prístupná v IS MAIS max. do 48 hodín od odoslania
    </li>
  </ol>

</base-dialog-template>
