export const stuff = [
  {
    name: 'Ing. Ondrej Látka, PhD.',
    role: 'Riaditeľ UK',
    fullRole: 'Riaditeľ Univerzitnej knižnice Technickej univerzity v Košiciach',
    items: [
      {
        icon: 'fa-phone',
        text: '+421 55 602 7711'
      },
      {
        icon: 'fa-envelope',
        text: 'ondrej.latka@tuke.sk'
      }
    ]
  },
  {
    name: 'Ing. Zuzana Rolík Krupašová',
    role: 'Referát HP',
    fullRole: 'Referát hospodársko-prevádzkový',
    items: [
      {
        icon: 'fa-phone',
        text: '+421 55 602 7700'
      },
      {
        icon: 'fa-envelope',
        text: 'zuzana.rolik.krupasova@tuke.sk'
      }
    ]
  },
  {
    name: 'Ing. Miroslav Ťahla, MBA',
    role: 'Vedúci OVS',
    fullRole: 'Vedúci oddelenia služieb a zástupca riaditeľa',
    items: [
      {
        icon: 'fa-phone',
        text: '+421 55 602 7777'
      },
      {
        icon: 'fa-envelope',
        text: 'miroslav.tahla@tuke.sk'
      }
    ]
  },
  {
    name: 'Ing. Marek Čopjak',
    role: 'Vedúci OVI',
    fullRole: 'Vedúci oddelenia vedeckých informácií',
    items: [
      {
        icon: 'fa-phone',
        text: '+421 55 602 7722'
      },
      {
        icon: 'fa-envelope',
        text: 'marek.copjak@tuke.sk'
      }
    ]
  },
  {
    name: 'Mgr. Matej Hudáček',
    role: 'Vedúci OKF',
    fullRole: 'Vedúci oddelenia knižničného fondu',
    items: [
      {
        icon: 'fa-phone',
        text: '+421 55 602 7744'
      },
      {
        icon: 'fa-envelope',
        text: 'matej.hudacek@tuke.sk'
      }
    ]
  }
];
