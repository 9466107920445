import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NewsType} from '../../types/news.type';
import {ALFRED_URL} from '../../app.urls';
import {AppCommunicationService} from '../../services/app-communication.service';
import {I18nService} from '../../i18n';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  lang = 'sk';

  news: NewsType[] = [];

  constructor(
    protected http: HttpClient,
    private appService: AppCommunicationService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    this.getData();

    this.i18n.state.subscribe({
      next: (value) => {
        this.lang = value;
      }
    });
  }

  getData() {
    this.appService.loadingOn();

    this.http
      .post(`${ALFRED_URL}/newsfeed/anonymous/GetAllByFilter`, {})
      .subscribe({
        next: value => {
          this.news = value['items'];

          this.news.sort((a, b) => {
            return new Date(b.createdDatetime).getTime() - new Date(a.createdDatetime).getTime();
          });

          this.appService.loadingOff();
        },
        error: err => {
          console.log(err);
          this.appService.loadingOff();
        }
      });
  }

}
