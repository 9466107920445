<div class="cube-portfolio container margin-bottom-60"
     [class.loading-content]="!loaded">

  <div class="content-xs">

    <div id="filters-container" class="cbp-l-filters-text content-xs">

      <ng-container *ngFor="let filter of filters; let i = index;">

        <div
          [attr.data-filter]="filter.data"
          [class.cbp-filter-item-active]="filter.data === '*'"
          class="cbp-filter-item"> {{filter.title}}</div>

        <ng-container *ngIf="i < filters.length - 1">|</ng-container>

      </ng-container>

    </div>
  </div>
  <div id="grid-container" class="cbp-l-grid-agency">


    <div class="cbp-item" *ngFor="let item of portfolio" [ngClass]="item.category">
      <div class="cbp-caption">
        <div class="cbp-caption-defaultWrap">
          <img [src]="item.image" alt="">
        </div>
        <div class="cbp-caption-activeWrap" (click)="clickedPortfolioItem(item)">
          <div class="cbp-l-caption-alignCenter">
            <div class="cbp-l-caption-body">
              <ul class="link-captions">
                <li><a [routerLink]="item.routerLink">
<!--                  <i class="rounded-x fa fa-link"></i>-->
                </a></li>
              </ul>
              <div class="cbp-l-grid-agency-title">{{item.title}}</div>
              <div class="cbp-l-grid-agency-desc">{{item.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
