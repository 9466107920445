export enum PortfolioItemEnum {
  fotoObraz = 'fotoObraz',
  plotrovanie = 'plotrovanie',
  scanRobot = 'scanRobot',
  xBaner = 'xBaner',
  drotenaVazba = 'drotenaVazba',
  card = 'card',
  wideScan = 'wideScan',
  tshirt = 'tshirt',
  rollup = 'rollup',
  VazbaV1 = 'VazbaV1',
  VazbaV2 = 'VazbaV2',
  VazbaV8 = 'VazbaV8',
  obaly = 'obaly',
  cd = 'cd',
  foto = 'foto',
  oneWayVision = 'oneWayVision',
  calendars = 'calendars',
  businessCards = 'businessCards',
  banners = 'banners',
  stickers = 'stickers',
  posters = 'posters',
  pvcTable = 'pvcTable',
  zp = 'zp',
  planografia = 'planografia',
  bulletin = 'bulletin',
  bag = 'bag',
  cityLight = 'cityLight'
}
