<div class="breadcrumbs">

  <a class="btn btn-back" (click)="goToPortfolio($event)"><i class="fa fa-arrow-left"></i></a>

  <div class="container">
    <h1 class="pull-left">{{item?.title | i18n}}</h1>
  </div>
</div>

<div class="container">

  <div class="content">

    <div class="carousel-container">

      <p-carousel [value]="item?.images" (onPage)="onImageChange($event)" [circular]="true">

        <ng-template let-image pTemplate="item">

          <div class="carousel-item">

            <img class="img-responsive" *ngIf="image?.url && ((image | itemIndex: item.images) === currentImageIndex)" [src]="imagePortfolioUrl + image?.url" loading="lazy">

          </div>

        </ng-template>
      </p-carousel>

    </div>

    <div class="row margin-bottom-20">
      <div class="col-sm-8" *ngFor="let headline of item?.headlines">
        <div class="headline">
          <h2>{{headline?.title | i18n}}</h2>
        </div>

        <p [innerHTML]="headline?.description"></p>

      </div>
      <div class="col-sm-4">
        <div class="headline"><h2>{{item?.priceTitle}}</h2></div>
        <ul class="list-unstyled project-details">
          <li *ngFor="let list of priceDescriptions" [innerHTML]="list"></li>
          <li *ngIf="item?.priceAsString" class="cena-produktu" [innerHTML]="item?.priceAsString"></li>
        </ul>
        <div class="headline"><h2>Viac informácií</h2></div>
        <ul class="list-unstyled project-details">
          <li><a href="mailto:print@tuke.sk">{{'print@tuke.sk'}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
