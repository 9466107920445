import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {getAllRandomBookNoveltyUrl} from '../app.urls';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BookNoveltyModel} from '../models/book-novelty.model';

@Injectable({
  providedIn: 'root'
})
export class AlfredService {

  constructor(
    private http: HttpClient
  ) {
  }

  getRandomBookNovelty(count: number = 4): Observable<BookNoveltyModel[]> {
    return this.http
      .get(getAllRandomBookNoveltyUrl(count))
      .pipe(map((res: any) => res['items'] as BookNoveltyModel[]));
  }
}
