import { Component, OnInit } from '@angular/core';
import {AlfredService} from '../../services/alfred.service';
import {BookNoveltyModel} from '../../models/book-novelty.model';
import {DashboardCarouselItemInterface} from './dashboard-carousel-item.interface';
import {Carousel} from 'primeng/carousel';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  carouselItems: DashboardCarouselItemInterface[] = [
    {
      id: 'books',
      backgroundImageUrl: 'assets/img/dashboard/3.jpg',
      header: 'Knižné novinky v našej knižnici',
      description1: null,
      description2: null,
      actions: [],
      overlay: true
    },
    {
      id: 'catalog',
      backgroundImageUrl: 'assets/img/dashboard/4.jpg',
      header: 'Online katalóg univerzitnej knižnice TUKE',
      description1: 'Nájdite knihy a skriptá potrebné k vášmu úspešnému štúdiu.',
      description2: 'Objednajte si publikácie z pohodlia domova.',
      actions: [
        {
          title: 'Katalóg Univerzitnej knižnice',
          href: 'http://opac.lib.tuke.sk/',
          class: 'btn-u'
        }
      ],
      overlay: true
    },
    {
      id: 'databases',
      backgroundImageUrl: 'assets/img/dashboard/1.jpg',
      header: 'Online katalóg externých databáz',
      description1: 'Hľadáš odbornú publikáciu alebo článok k tvojej téme?',
      description2: 'Skús si to vyhľadať medzi renomovanými zdrojmi z celého sveta.',
      actions: [
        {
          title: 'Katalóg Univerzitnej knižnice',
          href: 'http://naviga.cz/cz/naviga/',
          class: 'btn-u btn-brd btn-brd-hover btn-u-light'
        },
        {
          title: 'Summon',
          href: 'http://tuke.summon.serialssolutions.com/',
          class: 'btn-u'
        }
      ],
      overlay: true
    },
    {
      id: 'findBook',
      backgroundImageUrl: 'assets/img/dashboard/2.jpg',
      header: 'Nenašiel si hľadanú knihu?',
      description1: 'Nájdi ju v celoslovenskom katalógu kníh v inej knižnici.',
      description2: 'Následne si ju vypožičaj cez medziknižničnú výpožičnú službu.',
      actions: [
        {
          title: 'InfoGate',
          href: 'https://www.infogate.sk/',
          class: 'btn-u'
        },
        {
          title: 'MVS knihy',
          href: '/Library/Home/MvsBook',
          class: 'btn-u btn-brd btn-brd-hover btn-u-light'
        },
        {
          title: 'MVS časopisy',
          href: '/Library/Home/MvsMagazine',
          class: 'btn-u btn-brd btn-brd-hover btn-u-light'
        }
      ],
      overlay: true
    }
  ];

  books: BookNoveltyModel[] = [];

  constructor(
    private alfredService: AlfredService
  ) {

    Carousel.prototype.onTouchMove = () => { };

  }

  ngOnInit(): void {
    this.loadBooks();
  }

  loadBooks() {
    this.alfredService
      .getRandomBookNovelty()
      .subscribe(v => this.books = v);
  }
}
