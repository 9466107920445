<form class="sky-form" [formGroup]="form">

  <header>Informácie o žiadateľovi</header>

  <fieldset [formGroupName]="'user'">

    <div class="row">

      <div class="col-md-6">
        <label class="label">Meno, Priezvisko <span class="color-red">*</span></label>
        <label class="input">
          <i class="icon-prepend fa fa-user"></i>
          <input class="text-box single-line" formControlName="name" name="name" type="text">
        </label>
      </div>

      <div class="col-md-6">
        <label class="label">Pracovisko <span class="color-red">*</span></label>
        <label class="input">
          <i class="icon-prepend fa fa-building"></i>
          <input class="text-box single-line" formControlName="workcenter" name="workcenter" type="text">
        </label>
      </div>
    </div>

    <div class="row">

      <div class="col-md-6">
        <label class="label">Email <span class="color-red">*</span></label>
        <label class="input">
          <i class="icon-prepend fa fa-envelope"></i>
          <input class="text-box single-line" formControlName="email" name="email" type="text">
        </label>
      </div>

      <div class="col-md-6">
        <label class="label">Telefón, Klapka </label>
        <label class="input">
          <i class="icon-prepend fa fa-phone"></i>
          <input class="text-box single-line" formControlName="phone" name="phone" type="text">
        </label>
      </div>

    </div>

  </fieldset>

  <header>Informácie o dokumente</header>

  <fieldset [formGroupName]="'book'">
    <div class="row">

      <div class="col-md-4">
        <label class="label">Autor <span class="color-red">*</span></label>
        <label class="input">
          <input class="text-box single-line" name="author" formControlName="author" type="text">
        </label>
      </div>

      <div class="col-md-4">
        <label class="label">Názov knihy <span class="color-red">*</span></label>
        <label class="input">
          <input class="text-box single-line" name="title" formControlName="title" type="text">
        </label>
      </div>

      <div class="col-md-2">
        <label class="label">Rok vydania</label>
        <label class="input">
          <input class="text-box single-line" name="yearOfPublishing" formControlName="yearOfPublishing" type="text" >
        </label>
      </div>

      <div class="col-md-2">
        <label class="label">Číslo ISBN <span class="color-red">*</span></label>
        <label class="input">
          <input class="text-box single-line" name="isbn" formControlName="isbn" type="text">
        </label>
      </div>

    </div>
    <div class="row">
      <div class="col-md-8">
        <label class="label">Odkiaľ ste získali citáciu zdroja? </label>
        <label class="input">
          <input class="text-box single-line" name="source" formControlName="source" type="text" >
        </label>
      </div>
      <div class="col-md-4">
        <label class="label">Požiadavka platí do <span class="color-red">*</span></label>
        <label class="input">
          <input name="validUntil" formControlName="validUntil" type="text" class="hasDatepicker">
        </label>
      </div>
    </div>

    <section>
      <label class="label">Poznámka </label>
      <label class="textarea">
        <textarea cols="20" name="message" formControlName="message" rows="3"></textarea>
      </label>
    </section>
  </fieldset>

  <footer>
    <button type="submit" class="btn-u" [disabled]="!form.valid" (click)="submit($event)">Odoslať</button>
  </footer>

</form>
