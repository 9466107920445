import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-epc-correction-request-form',
  templateUrl: './epc-correction-request-form.component.html',
  styleUrls: ['./epc-correction-request-form.component.scss']
})
export class EpcCorrectionRequestFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
