<form class="sky-form">

  <header>Odosielateľ</header>

  <fieldset>

    <div class="row">

      <div class="col-md-6">

        <label class="label">Meno, Priezvisko </label>
        <label class="input">
          <i class="icon-prepend fa fa-user"></i>
          <input
            class="text-box single-line"
            name="name" type="text"
            required
            [(ngModel)]="form.name">
        </label>

      </div>

      <div class="col-md-6">

        <label class="label">Email </label>
        <label class="input">
          <i class="icon-prepend fa fa-envelope"></i>
          <input class="text-box single-line"
                 name="Email" type="email"
                 required
                 [(ngModel)]="form.email">
        </label>

      </div>
    </div>

    <section>
      <label class="label">Vzťah voči tuke <span class="color-red">*</span></label>
      <div class="inline-group">
        <label class="radio" *ngFor="let relation of feedbackRelations">
          <input name="relation"
                 [(ngModel)]="form.relation"
                 type="radio"
                 [value]="relation.value">
          <i class="rounded-x"></i>
          {{relation.text | i18n}}
        </label>
      </div>
    </section>

  </fieldset>

  <header>{{'Správa' | i18n}}</header>

  <fieldset>
    <section>
      <label class="label">Váš návrh, námet, pripomienka <span class="color-red">*</span></label>
      <label class="textarea">
        <textarea cols="20" name="message" [(ngModel)]="form.message" rows="3"></textarea>
      </label>
    </section>
  </fieldset>

  <footer>
    <button type="submit" class="btn-u" (click)="submit($event)">Odoslať</button>
  </footer>

</form>
