export const FeedbackRelations = [
  {
    text: 'Študent',
    value: 'Student'
  },
  {
    text: 'PhD. študent',
    value: 'PhD.Student'
  },
  {
    text: 'Pedagogický pracovník',
    value: 'Pedagog'
  },
  {
    text: 'Nepedagogický pracovník',
    value: 'Nepedagog'
  },
  {
    text: 'iné (mimo TUKE)',
    value: 'Ine'
  }
];
