<div class="wrapper">

  <div class="carousel-section">

    <p-carousel [value]="carouselItems" [autoplayInterval]="12000">

      <ng-template let-item pTemplate="item">

        <div class="carousel-item" [class.overlay]="item.overlay">

          <div class="background-carousel-img" *ngIf="item?.backgroundImageUrl" [style.background-image]="'url(' + item?.backgroundImageUrl + ')'"></div>

          <div class="carousel-content">

            <div class="carousel-content__header" *ngIf="item?.header">
              {{item?.header | i18n}}
            </div>

            <div class="carousel-content__new-books" *ngIf="item?.id && item?.id === 'books'">

              <div class="book-container" *ngFor="let book of books">

                <a [href]="book?.opacUrl" target="_blank" [title]="book?.title" *ngIf="book?.opacUrl">

                  <img [src]="book?.coverImageBlob" class="book-image">

                </a>

              </div>

            </div>

            <div class="carousel-content__description" *ngIf="item?.description1 || item?.description2">
              {{item?.description1 | i18n}}
              <ng-container *ngIf="item?.description2">
                <br>
                {{item?.description2 | i18n}}
              </ng-container>
            </div>

            <div class="carousel-content__actions" *ngIf="item?.actions && item?.actions?.length > 0">
              <ng-container *ngFor="let button of item?.actions">
                <a [href]="button?.href" target="_blank" class="btn-u">{{button?.title | i18n}}</a>
              </ng-container>
            </div>
          </div>

        </div>

      </ng-template>
    </p-carousel>


  </div>

  <app-dashboard-bottom-section></app-dashboard-bottom-section>

</div>
