/* ### BASE URL ### */
import {DEV_BUILD_USE_HTTPS, DEVAPI_BUILD, PROD_BUILD_USE_HTTPS, PRODUCTION_BUILD, SYSTEM_NAME} from './app.settings';
import {FormTypesEnum} from './components/forms/form-types.enum';
import {StringUtils} from './misc/string-utils';
import {SYSTEM_NAME_ALFRED, SYSTEM_NAME_AUTH, SYSTEM_NAME_LIBADMIN} from './constants';

function getApiProtocol(): string {
  if (PRODUCTION_BUILD) {
    if (PROD_BUILD_USE_HTTPS) {
      return 'https';
    } else {
      return 'http';
    }
  }

  if (DEVAPI_BUILD) {
    if (DEV_BUILD_USE_HTTPS) {
      return 'https';
    } else {
      return 'http';
    }
  }

  return 'http';
}

export const PROD_HOST = 'portal.tuke.sk';         // production hostname
export const PROD_HOST_PORT = '';                      // production host port (empty for default)
export const PROD_API_HOST = 'portal.tuke.sk';        // production API hostname
export const PROD_API_HOST_PORT = '';                  // production API host port (empty for default)
export const DEV_HOST = 'portal.dev.msweb.tuke.sk';      // development hostname
export const DEV_HOST_PORT = '';                       // development host port (empty for default)
export const DEV_API_HOST = 'portal.dev.msweb.tuke.sk';     // development API hostname
export const DEV_API_HOST_PORT = '';                       // development API host port (empty for default)

export function getBaseApiUrl(systemName?: string): string {
  if (PRODUCTION_BUILD) {
    return `${getApiProtocol()}://api.${systemName}.${PROD_API_HOST}${
      StringUtils.isNullOrEmpty(PROD_API_HOST_PORT) ? '' : (':' + PROD_API_HOST_PORT)
    }/api`;
  }

  return `${getApiProtocol()}://api.${systemName}.${DEV_API_HOST}${
    StringUtils.isNullOrEmpty(DEV_API_HOST_PORT) ? '' : (':' + DEV_API_HOST_PORT)
  }/api`;
}

export function getBaseFrontendUrl(systemName?: string): string {
  if (PRODUCTION_BUILD) {
    if (systemName === SYSTEM_NAME_AUTH) {
      return `https://${PROD_HOST}${
        StringUtils.isNullOrEmpty(PROD_HOST_PORT) ? '' : (':' + PROD_HOST_PORT)
      }`;
    }
    return `https://${systemName}.${PROD_HOST}${
      StringUtils.isNullOrEmpty(PROD_HOST_PORT) ? '' : (':' + PROD_HOST_PORT)
    }`;
  }

  return `https://${systemName}.${DEV_HOST}${
    StringUtils.isNullOrEmpty(DEV_HOST_PORT) ? '' : (':' + DEV_HOST_PORT)
  }`;
}

export const ALFRED_URL = getBaseApiUrl(SYSTEM_NAME_ALFRED);
export const LIBADMIN_URL = getBaseApiUrl(SYSTEM_NAME_LIBADMIN);


const API_FACULTY_CODEBOOK = '/codebook/getFaculties';
const API_DEPARTMENT_CODEBOOK = '/codebook/getDepartments/';

export function getFacultyCodebookUrl() {
    return getBaseApiUrl(SYSTEM_NAME) + API_FACULTY_CODEBOOK;
}

export function getDepartmentCodebookUrl(facultyId) {
    return getBaseApiUrl(SYSTEM_NAME) + API_DEPARTMENT_CODEBOOK + '/' + facultyId;
}

export function getUKPortalsUrl(systemName: string) {
  return `${getBaseFrontendUrl(systemName)}`;
}

export function getUKServicesUrl(formType: FormTypesEnum) {
  return `${getBaseFrontendUrl(SYSTEM_NAME_ALFRED)}/#/forms/${formType}`;
}

export function getAllRandomBookNoveltyUrl(count: number) {
  return `${ALFRED_URL}/bookNovelty/getAllRandom/${count}`;
}
