<base-dialog-template [dialogHidden]="dialogHidden" (onClose)="close()">

  <ng-container heading *ngIf="title">{{title}}</ng-container>

  <table class="table" content>
    <thead>
    <tr>
      <th>Kategoria</th>
      <th>Popis</th>
      <th>Vypozicna lehota</th>
      <th>Upomienka</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="tabulkaOddelovaciaCiara-top" rowspan="2">
        A
      </td>
      <td class="tabulkaOddelovaciaCiara-top" rowspan="2">
        učitelia, vedeckí pracovníci
      </td>
      <td class="tabulkaOddelovaciaCiara-top" rowspan="2">
        2 roky
      </td>
      <td class="tabulkaOddelovaciaCiara-top">
        I., II.: <strong>0&nbsp;€</strong>
      </td>
    </tr>
    <tr>
      <td>
        III.: <strong>1,50&nbsp;€</strong>
      </td>
    </tr>
    <tr>
      <td class="tabulkaOddelovaciaCiara-top" rowspan="3">
        B
      </td>
      <td class="tabulkaOddelovaciaCiara-top" rowspan="3">
        študenti všetkých foriem štúdia na TUKE
      </td>
      <td class="tabulkaOddelovaciaCiara-top" rowspan="3">
        1 semester
      </td>
      <td class="tabulkaOddelovaciaCiara-top">
        I.: <strong>0,50&nbsp;€</strong>
      </td>
    </tr>
    <tr>
      <td>
        II.: <strong>1&nbsp;€</strong>
      </td>
    </tr>
    <tr>
      <td>
        III.: <strong>1,50&nbsp;€</strong>
      </td>
    </tr>
    <tr>
      <td class="tabulkaOddelovaciaCiara-top" rowspan="2">
        C
      </td>
      <td class="tabulkaOddelovaciaCiara-top" rowspan="2">
        ostatní zamestnanci TUKE
      </td>
      <td class="tabulkaOddelovaciaCiara-top" rowspan="2">
        3 mesiace
      </td>
      <td class="tabulkaOddelovaciaCiara-top">
        I., II.: <strong>0&nbsp;€</strong>
      </td>
    </tr>
    <tr>
      <td>
        III.: <strong>1,50&nbsp;€</strong>
      </td>
    </tr>
    <tr>
      <td class="tabulkaOddelovaciaCiara-top" rowspan="3">
        D
      </td>
      <td class="tabulkaOddelovaciaCiara-top" rowspan="3">
        hosťujúci profesori, docenti, lektori
      </td>
      <td class="tabulkaOddelovaciaCiara-top" rowspan="3">
        1 mesiac
      </td>
      <td class="tabulkaOddelovaciaCiara-top">
        I.: <strong>0,50&nbsp;€</strong>
      </td>
    </tr>
    <tr>
      <td>
        II.: <strong>1&nbsp;€</strong>
      </td>
    </tr>

    <tr>
      <td>
        III.: <strong>1,50&nbsp;€</strong>
      </td>
    </tr>
    </tbody>
  </table>

</base-dialog-template>
