<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    {{content}}
</div>
<div class="modal-footer">
    <button type="button" class="btn" [ngClass]="button?.class"
            *ngFor="let button of buttons"
            (click)="button.action()">{{button?.title}}</button>
</div>
