import { Component, OnInit } from '@angular/core';
import {BaseCarouselItemInterface} from '../../models/base-carousel-item.interface';
import {carouselItems} from './data/carousel-items';
import {services} from './data/services';
import {stuff} from './data/stuff';
import {openingHours} from './data/opening-hours';
import {contact} from './data/contact';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  carouselItems = carouselItems;

  services = services;

  stuff = stuff;

  openingHours = openingHours;

  contact = contact;

  constructor() { }

  ngOnInit() {
  }

}
