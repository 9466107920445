import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable()
export class AppCommunicationService {

    loadingAnimation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loadingAnimationText: Subject<string> = new Subject<string>();

    constructor() {
    }

    loadingOn(text = null) {
        if (text) {
            this.loadingAnimationText.next(text);
        }
        this.loadingAnimation.next(true);
    }

    loadingOff() {
        this.loadingAnimation.next(false);
        this.loadingAnimationText.next(null);
    }
}
