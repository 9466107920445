export enum FormTypesEnum {
  acquisition = 'acquisition',
  buyDocument = 'buyDocument',
  digitalCopyRequest = 'digitalCopyRequest',
  education = 'education',
  epcCorrectionRequest = 'epcBiblioCorrection',
  feedback = 'feedback',
  mvsBooks = 'mvsBooks',
  mvsMagazine = 'mvsMagazine',
  priorityRequest = 'priorityRequest',
  searchRequest = 'searchRequest'
}
