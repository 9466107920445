import {Component, OnInit} from '@angular/core';
import {FaqSystems, FaqSystemsEnum} from '../../enums/faq-systems.enum';
import {OverlayService} from '../overlay-dialog/overlay-service';
import {ThesisRegisterFaqDialogComponent} from '../overlay-dialog/faq/thesis-register-faq-dialog/thesis-register-faq-dialog.component';
import {FaqDialogs} from './types/faq-dialogs';
import {IsbnRegisterFaqDialogComponent} from '../overlay-dialog/faq/isbn-register-faq-dialog/isbn-register-faq-dialog.component';
import {ArticleRegisterFaqDialogComponent} from '../overlay-dialog/faq/article-register-faq-dialog/article-register-faq-dialog.component';
import {BorrowTerminsFaqDialogComponent} from '../overlay-dialog/faq/borrow-termins-faq-dialog/borrow-termins-faq-dialog.component';
import {OrderBookFaqDialogComponent} from '../overlay-dialog/faq/order-book-faq-dialog/order-book-faq-dialog.component';
import {HttpClient} from '@angular/common/http';
import {LIBADMIN_URL} from '../../app.urls';
import {ActivatedRoute} from '@angular/router';
import {first} from 'rxjs';
import {copyToClipboard} from '../../misc/copy-to-clipboard';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqDialogs = FaqDialogs;

  faqSystems = FaqSystems;

  currentSystem: FaqSystemsEnum = FaqSystemsEnum.etd;

  items = [];

  faqItemToOpen = null;

  openedFaqItem;

  accordionTabStates: boolean[] = [];

  constructor(
    protected overlayService: OverlayService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.generateTabStates();

    this.checkParamInUrl();

    this.reloadFaqItems();
  }

  disableAllTabStates() {
    this.accordionTabStates.forEach(i => {
      i = false;
      return i;
    });
  }

  generateTabStates() {
    this.items
      .forEach(i => this.accordionTabStates.push(false));
  }

  checkParamInUrl() {
    this.activatedRoute
      .queryParams
      .pipe(first())
      .subscribe({
        next: params => {
          console.log(params)
          if (params && params.system) {
            this.currentSystem = params.system.toLowerCase();
            this.changeFaqSystem(this.currentSystem);
          }

          if (params && params.item) {
            this.faqItemToOpen = params.item;
          }
        }
      })
  }

  openModal(dialog: FaqDialogs) {
    switch (dialog) {
      case FaqDialogs.thesisRegister:
        this.overlayService.show(ThesisRegisterFaqDialogComponent);
        break;
      case FaqDialogs.isbnRegister:
        this.overlayService.show(IsbnRegisterFaqDialogComponent);
        break;
      case FaqDialogs.articleRegister:
        this.overlayService.show(ArticleRegisterFaqDialogComponent);
        break;
      case FaqDialogs.borrowTermins:
        this.overlayService.show(BorrowTerminsFaqDialogComponent);
        break;
      case FaqDialogs.orderBook:
        this.overlayService.show(OrderBookFaqDialogComponent);
        break;
    }
  }

  changeFaqSystem(system: FaqSystemsEnum) {
    this.currentSystem = system;
    this.reloadFaqItems();
  }

  reloadFaqItems() {
    this.http
      .get(`${LIBADMIN_URL}/faq/getPublicFaqBySystem/${this.currentSystem}`)
      .subscribe({
        next: value => {
          this.items = value['items'];

          this.generateTabStates();

          setTimeout(() => {
            this.autoOpenFaqItem();
          }, 100);
        }
      })
  }

  autoOpenFaqItem() {
    if (this.faqItemToOpen) {
      const el = document.getElementById(this.faqItemToOpen);

      console.log(el)

      if (el) {
        this.disableAllTabStates();
        window.scroll({top: el.offsetTop, behavior: 'smooth'});
        this.accordionTabStates[this.faqItemToOpen] = true;
      }
    }
  }

  copyQuestionUrl(e: Event, id: number) {
    e.stopImmediatePropagation();
    const url = `${window.location.origin}/#/faq?system=${this.currentSystem}&item=${id}`;
    copyToClipboard(url);
  }

}
