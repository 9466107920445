import {Component, OnInit} from '@angular/core';
import {I18nService} from '../../i18n';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  infoSection = {
    ukLogo: '/assets/img/custom/UK/Logo/logoUkFooter.png',
    tukeLogo: '/assets/img/custom/UK/Logo/LogoTuFooterEEE.png',
    description: 'Poslaním knižnice je informačné zabezpečenie vedeckého výskumu a pedagogického procesu na TUKE. Služby poskytujeme pedagógom, výskumníkom a ostatným zamestnancom TUKE, študentom TUKE a iným záujemcom verejného prostredia.'
  };

  contactSection = {
    text: 'Kontaktujte nás',
    address1: 'Univerzitná knižnica TUKE',
    address2: 'Němcovej 7, Košice 042 00 Slovensko',
    phone: '+421 55 602 7700',
    mail: 'kniznica@tuke.sk'
  };

  categories = [
    {
      title: 'Legislatíva',
      items: [
        {
          title: 'Organizačný poriadok UK',
          type: 'modal',
          data: 'org_poriadok.pdf'
        },
        {
          title: 'Štatút univerzitnej knižnice TUKE',
          type: 'modal',
          data: 'statut_uk.pdf'
        },
        {
          title: 'Knižničný poriadok',
          type: 'modal',
          data: 'TUKE_KPUK.pdf'
        },
        {
          title: 'Projekty',
          type: 'modal',
          data: '.projekts'
        },
        {
          title: 'Ochrana osobných údajov',
          type: 'modal',
          data: '.GDPR'
        },
        {
          title: 'VOP GoPay',
          type: 'modal',
          data: 'VOP.pdf'
        }
      ]
    },
    {
      title: 'Súvisiace odkazy',
      items: [
        {
          title: 'TUKE',
          type: 'link',
          data: 'http://www.tuke.sk/'
        },
        {
          title: 'Libresso',
          type: 'link',
          data: 'http://www.sdaj.tuke.sk/l-uik.html'
        },
        {
          title: 'Telefónny zoznam TUKE',
          type: 'link',
          data: 'http://info.tuke.sk/telefon/tfzoznam/'
        },
        {
          title: 'ÚVT',
          type: 'link',
          data: 'https://www.uvt.tuke.sk/'
        },
        {
          title: 'OHSE',
          type: 'link',
          data: 'https://ohse.tuke.sk/'
        },
        {
          title: 'IS MAIS',
          type: 'link',
          data: 'https://mais.tuke.sk/'
        }
      ]
    }
  ];


  currentYear = new Date().getFullYear();

  constructor(
    private i18n: I18nService
  ) {
  }

  ngOnInit() {

    this.i18n.state.subscribe(() => {
      this.checkImages();
    });

  }

  checkImages() {
    this.infoSection.ukLogo = `/assets/img/custom/UK/Logo/logo-footer-${this.i18n.currentLanguage.key}.png`;
  }

  openModal(file: string) {
    window.open(`assets/files/footer/${file}`, '_blank');
  }

}
