export const SYSTEM_NAME_AUTH = 'AUTH';
export const SYSTEM_NAME_EPC = 'EPC';
export const SYSTEM_NAME_ETD = 'ETD';
export const SYSTEM_NAME_ISBN = 'ISBN';
export const SYSTEM_NAME_ROOMBOOK = 'ROOMBOOK';
export const SYSTEM_NAME_ZP = 'ZP';
export const SYSTEM_NAME_UIK = 'UIK';
export const SYSTEM_NAME_HELPDESK = 'HELPDESK';
export const SYSTEM_NAME_TOOLS = 'TOOLS';
export const SYSTEM_NAME_LIBADMIN = 'LIBADMIN';
export const SYSTEM_NAME_WAREHOUSE = 'WAREHOUSE';
export const SYSTEM_NAME_ALFRED = 'ALFRED';


export const SYSTEM_FULL_NAME_ETD = 'thesisEvidence';
export const SYSTEM_FULL_NAME_ROOMBOOK = 'roombook';
export const SYSTEM_FULL_NAME_WAREHOUSE = 'warehouse';
export const SYSTEM_FULL_NAME_ALFRED = 'alfred';
export const SYSTEM_FULL_NAME_LIBADMIN = 'libadmin';
