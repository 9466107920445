import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {delay, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class JsonApiService {

  constructor(private http: HttpClient) {
  }

  public fetch(url): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'assets/api' + url)
      .pipe(delay(100))
      .pipe(map(this.extractData));
  }

  private getBaseUrl() {
    return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/';
  }

  private extractData(res: any) {
    if (res) {
      return res.data || res;
    } else {
      return {};
    }
  }

  private handleError(error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    throw errMsg;
  }

}
