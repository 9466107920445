import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {OverlayService} from './overlay-service';

@Component({
  selector: 'overlay-dialog-root',
  templateUrl: 'overlay-dialog-root.html',
  styleUrls: ['overlay-dialog-root.scss']
})
export class OverlayDialogRootComponent {

  protected component: ComponentRef<any>;

  @ViewChild('container', {static: false, read: ViewContainerRef})
  container: ViewContainerRef;

  constructor(
    protected overlayService: OverlayService,
    protected componentFactoryResolver: ComponentFactoryResolver,
  ) {

    this.overlayService.registerOverlayComponent(this);

  }

  public removeComponent() {

    if (!this.component) {
      throw new Error('No component is rendered right now!');
    }

    this.container.clear();
    this.component.destroy();
    this.component = null;

  }

  public showComponent<T>(component: Type<T>): ComponentRef<T> {

    if (this.component) {
      throw new Error('Some component is already rendered!');
    }

    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = this.container.createComponent(factory);
    this.component = componentRef;
    return componentRef;

  }


}
