import { Component, OnInit } from '@angular/core';
import {I18nService, languages} from '../../i18n';
import {LanguageModel} from '../../i18n/languages.model';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

  languages: LanguageModel[] = languages;

  currentLanguage: LanguageModel;

  public expanded = false;

  constructor(
    protected translationService: I18nService
  ) { }

  ngOnInit() {

    this.currentLanguage = this.languages.find(l => l.key === this.translationService.currentLanguage.key);

    if (!this.currentLanguage) {
      this.currentLanguage = this.languages[0];
    }

    this.translationService
      .subscribe((() => {
        const language = this.languages.find(lang => lang.key === this.translationService.currentLanguage.key);

        if (language) {
          this.currentLanguage = language;
        }
      }), null);
  }

  clickedIcon() {
    this.expanded = !this.expanded;
  }

  async selectLanguage(lang: LanguageModel) {
    await this.translationService.setLanguage(lang);
    this.expanded = false;
  }

}
