import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-mvs-books-form',
  templateUrl: './mvs-books-form.component.html',
  styleUrls: ['./mvs-books-form.component.scss']
})
export class MvsBooksFormComponent implements OnInit {

  form: FormGroup;

  constructor(
    protected fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      user: this.fb.group({
        name: ['', [Validators.required]],
        workcenter: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', [Validators.required]]
      }),
      book: this.fb.group({
        author: ['', [Validators.required]],
        title: ['', [Validators.required]],
        yearOfPublishing: [''],
        isbn: ['', [Validators.required]],
        source: [''],
        validUntil: ['', [Validators.required]],
        message: ['']
      })
    });
  }

  submit(e: Event) {
    e.preventDefault();
    console.log(this.form);
  }

}
