<div class="portfolio container">

  <div class="portfolio-categories">

    <ng-container *ngFor="let filter of filters; let i = index;">

      <div
        [attr.data-filter]="filter"
        [class.data-filter-active]="filter === currentFilter"
        (click)="changedFilter(filter)"
        class="portfolio-category-item"> {{filter | i18n}}</div>

      <ng-container *ngIf="i < filters.length - 1">|</ng-container>

    </ng-container>

  </div>

  <div class="text-center">
    {{'V prípade otázok alebo objednávok nás kontaktujte na' | i18n}} <a href="mailto:print@tuke.sk">{{'print@tuke.sk'}}</a>
  </div>

  <ng-container *ngIf="filteredItems && filteredItems.length > 0">

    <div class="portfolio-items" [@listAnimationContainer]="filteredItems?.length">

      <div class="portfolio-item" @listAnimationItem *ngFor="let item of filteredItems" (click)="openPortfolioDetail(item)">

        <img *ngIf="item.mainImageUrl" [src]="imagePortfolioUrl + item.mainImageUrl">

        <div class="portfolio-item-content" *ngIf="item.title">

<!--          <div class="portfolio-item-content-title" *ngIf="item.title">{{item.title}}</div>-->

<!--          <div class="portfolio-item-content-desc" *ngIf="item.description">{{item.description}}</div>-->

        </div>

      </div>

    </div>

  </ng-container>

</div>
