export const services = [
  {
    iconClass: 'icon-line icon-communication-058',
    header: 'Konzultačno-informačná služba',
    text: 'Pracovisko konzultačných služieb poskytuje používateľom knižnice informácie o fonde knižnice a službách knižnice.',
    phone: '+421 55 602 7719'
  },
  {
    iconClass: 'line-icon-page  icon-education-162',
    header: 'Príjem publikačnej činnosti',
    text: 'Príjem a výdaj podkladov na evidenciu publikačnej činnosti pracovníkov TU Košice.',
    phone: '+421 55 602 7718'
  },
  {
    iconClass: 'icon-line icon-education-056',
    header: 'Výpožičná služba',
    text: 'Pracovisko výpožičných služieb zabezpečuje evidenciu čitateľov podľa kategórií a vytváranie čitateľského konta. Spracovanie objednanej literatúry a príprava zo skladového systému na výdaj vo výpožičnej službe.',
    phone: '+421 55 602 7721'
  },
  {
    iconClass: 'icon-line icon-printer',
    header: 'UK Copycentrum',
    text: 'Pracovisko Copycentra UK poskytuje širokú ponuku reprografických služieb. Zameriava sa predovšetkým na potreby študentov od rôznej tlače, skenovania, laminovania, cez kompletné viazanie záverečných prác.',
    phone: '+421 55 602 7770'
  }
];
