import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'base-dialog-template',
  templateUrl: './base-dialog-template.component.html',
  styleUrls: ['./base-dialog-template.component.scss']
})
export class BaseDialogTemplateComponent implements OnInit {

  @Input() dialogHidden = true;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  close() {
    this.onClose.emit(true);
  }

}
