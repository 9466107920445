import { NgModule } from '@angular/core';
import { PdfViewerComponent } from './pdf-viewer.component';
import { PdfViewerModule as Ng2PdfViewer } from 'ng2-pdf-viewer';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PdfService } from './pdf.service';
import {I18nModule} from '../../i18n/i18n.module';
import {ProgressBarModule} from 'primeng/progressbar';

@NgModule({
  exports: [
    PdfViewerComponent
  ],
  imports: [
    ModalModule,
    I18nModule,
    ProgressBarModule,
    Ng2PdfViewer,
    CommonModule,
    FormsModule,
    TooltipModule,
    I18nModule,
    ProgressBarModule,
  ],
  declarations: [
    PdfViewerComponent
  ],
  providers: [
    PdfService
  ]
})

export class PdfViewerModule {}
