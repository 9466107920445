import { Component, OnInit } from '@angular/core';
import {DashboardBottomSectionItemInterface} from './dashboard-bottom-section-item.interface';


@Component({
  selector: 'app-dashboard-bottom-section',
  templateUrl: './dashboard-bottom-section.component.html',
  styleUrls: ['./dashboard-bottom-section.component.scss']
})
export class DashboardBottomSectionComponent implements OnInit {

  items: DashboardBottomSectionItemInterface[] = [
    {
      routerLink: '/news',
      icon: 'fa-newspaper-o',
      title: 'Aktuality',
      text: 'Najnovšie informácie o dianí v Univerzitnej knižnici'
    },
    {
      routerLink: '/about',
      icon: 'fa-info',
      title: 'Dôležité informácie',
      text: 'Otváracie hodiny, vedenie Univerzitnej knižnice, kontakty'
    },
    {
      routerLink: '/digitalLibrary',
      icon: 'fa-arrows',
      title: 'Online vyhľadávače',
      text: 'Všetky potrebné informácie o dostupných katalógoch, vyhľadávačoch a podmienkach využívania elektronických zdrojov.'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
