import {NgModule} from '@angular/core';
import {LanguageSwitcherComponent} from './language-switcher.component';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule
  ],
  exports: [
    LanguageSwitcherComponent
  ],
  declarations: [
    LanguageSwitcherComponent
  ]
})

export class LanguageSwitcherModule {}
