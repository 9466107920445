<div bsModal
     id="pdfViewModal"
     #pdfViewModal="bs-modal"
     class="modal fade"
     tabindex="-1"
     role="dialog"
     aria-labelledby="pdfViewModal"
     aria-hidden="true">
  <div class="modal-dialog modal-lg">

    <div class="modal-content">

      <div class="modal-header">

        <h4 class="modal-title text-uppercase">{{pdfData.title | i18n}}</h4>


        <button type="button" class="close" (click)="close()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="pdf-loading-container" *ngIf="loading">
          <p-progressBar
            [showValue]="false"
          ></p-progressBar>
        </div>
        <pdf-viewer [src]="options.src"
                    [page]="options.currentPage"
                    (page-rendered)="onPageRendered()"
                    [show-all]="false"
                    [render-text]="false"
                    [autoresize]="true"
                    [original-size]="true"
                    [zoom]="options.zoom"
                    (on-progress)="checkLoadingComplete()"
                    (after-load-complete)="onPdfLoaded($event)"></pdf-viewer>
      </div>
      <div class="modal-footer" *ngIf="!loading">
        <div class="row">
          <div class="col-sm-4 col-xs-12">
            <div class="text-input-container pull-left">
              <!--                    <span>{{'searchByText' | i18n}}: </span>-->
              <!--                    <form class="smart-form display-inline">-->
              <!--                        <label class="input">-->
              <!--                            <input type="text" name="searchText" [(ngModel)]="options.searchText">-->
              <!--                        </label>-->
              <!--                    </form>-->
              <!--                    <button class="btn btn-default" (click)="searchByText()"><i class="fa fa-search"></i></button>-->

              <span>{{'goToPage' | i18n}}: </span>
              <form class="smart-form display-inline">
                <label class="input">
                  <input type="number" name="currentPage" [(ngModel)]="options.currentPage" [min]="1"
                         [max]="options.totalPages">
                </label>
              </form>
            </div>
          </div>
          <div class="col-sm-8 col-xs-12">
            <div class="controls-container pull-right">
              <button type="button" class="btn btn-default" [tooltip]="'goToFirstPage' | i18n" (click)="firstPage()"
                      [disabled]="options.currentPage === 1">
                <i class="fa fa-angle-double-left"></i>
              </button>
              <button type="button" class="btn btn-default" [tooltip]="'previousPage' | i18n" (click)="prevPage()"
                      [disabled]="options.currentPage === 1">
                <i class="fa fa-angle-left"></i>
              </button>
              <button type="button" class="btn btn-default" [tooltip]="'nextPage' | i18n" (click)="nextPage()"
                      [disabled]="options.currentPage === options.totalPages">
                <i class="fa fa-angle-right"></i>
              </button>
              <button type="button" class="btn btn-default" [tooltip]="'goToLastPage' | i18n" (click)="lastPage()"
                      [disabled]="options.currentPage === options.totalPages">
                <i class="fa fa-angle-double-right"></i>
              </button>
              <button type="button" class="btn btn-default" [tooltip]="'zoomOut' | i18n" (click)="zoomOut()">
                <i class="fa fa-search-minus"></i>
              </button>
              <button type="button" class="btn btn-default" [tooltip]="'zoomIn' | i18n" (click)="zoomIn()">
                <i class="fa fa-search-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
