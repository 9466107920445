<div class="container content">

  <div class="section">

    <div class="title-box-v2">

      <h2 class="color-green">{{ 'Obsah' | i18n }}</h2>

    </div>

    <div class="content-container">

      <div class="content-col">

        <a class="display-block" *ngFor="let item of contentLeft" (click)="scrollTo(item.href)">{{item.text | i18n}}</a>

      </div>

      <div class="content-col">

        <a class="display-block" *ngFor="let item of contentRight" (click)="scrollTo(item.href)">{{item.text | i18n}}</a>

      </div>

    </div>

  </div>

  <div class="section">

    <div class="title-box-v2">

      <h2 class="color-green">{{ 'Skratky' | i18n }}</h2>

    </div>

    <div class="shadow-wrapper margin-bottom-50">

      <ul>
        <li><span class="font-bold">ZP</span> <span >–</span> {{ 'záverečná práca' | i18n }}</li>
        <li><span class="font-bold">LZ</span> <span >–</span> {{ 'licenčná zmluva' | i18n }}</li>
        <li><span class="font-bold">CRZP</span> <span >–</span> {{ 'Centrálny register záverečných prác' | i18n }}</li>
        <li><span class="font-bold">IS ETD</span> <span >–</span> {{ 'Informačný systém na registráciu záverečných prác' | i18n }}</li>
      </ul>


    </div>

  </div>

  <div class="section">

    <div class="title-box-v2">

      <h2 class="color-green">{{ 'Slovník' | i18n }}</h2>

    </div>

    <div class="shadow-wrapper margin-bottom-50">

      <h4>{{ 'Stav záverečnej práce' | i18n }}</h4>

      <ul>
        <li><span class="font-bold">{{ 'Načítaná' | i18n }}</span> – {{'ZP nebola úspešne zaregistrovaná. Pravdepodobne autor neprešiel všetky kroky registrácie korektne.' | i18n}}
        </li>
        <li><span class="font-bold">{{ 'Registrovaná' | i18n }}</span> – {{'autor záverečnú prácu úspešne zaregistroval. Tento stav trvá zvyčajne do nasledujúceho pracovného dňa. Počas tohto stavu môže autor prácu znovu nahrať s opraveným textom, doplneným obrázkom, prípadne ďalšou úpravou. Zmena môže byť aj na strane IS MAIS, napr. zmena oponenta, úprava odkladu práce, zmena kľúčových slov. Po opätovnom vstupe do procesu registrácie sa pôvodná práca zmaže.' | i18n}}
        </li>
        <li><span class="font-bold">{{ 'Uzamknutá' | i18n }}</span> – {{'ZP je skontrolovaná pracovníkmi knižnice, autor už nemá možnosť prácu editovať.' | i18n}}
        </li>
        <li><span class="font-bold">{{ 'Odoslaná do CRZP' | i18n }}</span> – {{'ZP bola odoslaná na kontrolu originality, výsledok kontroly je do 5 pracovných dní.' | i18n}}
        </li>
        <li><span class="font-bold">{{ 'S protokolom' | i18n }}</span> – {{'ZP s dodaným protokolom originality z CRZP.' | i18n}}</li>
      </ul>

    </div>

  </div>

  <div class="section">

    <div class="title-box-v2">

      <h2 class="color-green">{{'Postup pre záverečné práce' | i18n}}</h2>

    </div>

    <p>
      {{'Záverečné práce (ďalej “ZP”) na TUKE sa odovzdávajú v elektronickej i tlačenej forme do Univerzitnej knižnice TUKE (ďalej “knižnica”), ktorá je lokálnym (univerzitným) úložiskom pre komunikáciu s' | i18n}}
      <a (click)="clickedOpacCrzp()">{{'Centrálnym registrom záverečných prác' | i18n}}</a>.
    </p>

    <p>
      {{'Záverečné práce sa odovzdávajú elektronicky cez portál knižnice – informačný systém' | i18n}} <a
      (click)="clickedEtdButton()">ETD</a>. {{'Z tohto portálu sa práca po kontrole pracovníkom knižnice zasiela do CRZP, kde sa vygeneruje protokol o kontrole originality záverečnej práce.' | i18n}}
    </p>

    <small>
      {{'Ak ste ZP nahrali v predošlom akademickom roku a v tomto roku ju len budete obhajovať a na jej obsahu a prílohách sa nič nemenilo, prácu je potrebné zaregistrovať nanovo, po uzamknutí práce sa vygeneruje nový protokol o kontrole originality ZP.' | i18n}}
    </small>

    <div class="shadow-wrapper margin-bottom-50">

      <h4 [id]="sectionIdsEnum.tema">
        {{'1. Téma ZP' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.tema)"></i>
      </h4>

      <p>
        {{'Návrhy tém pre záverečné práce sa zverejňujú v informačnom systéme' | i18n}} <a (click)="clickedMais()">MAIS</a>. {{'Termín zverejnenia tém je určený podľa harmonogramu príslušného akademického roka.' | i18n}}
      </p>

      <p>
        {{ 'V' | i18n }} <a (click)="clickedMais()">IS MAIS</a> {{'si pod záložkou' | i18n}} <span class="font-bold">„{{'Záverečná práca' | i18n}}”</span>
        {{'vyberte zvolenú tému ZP a prihláste sa na ňu.' | i18n}}
      </p>

      <h4 [id]="sectionIdsEnum.stiahnutSablonu">
        {{'2. Stiahnutie šablóny ZP' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.stiahnutSablonu)"></i>
      </h4>

      <p>
        {{'Prihláste sa do' | i18n}} <a (click)="clickedEtdButton()">IS ETD</a>
      </p>

      <p>
        {{'Kliknite na záložku' | i18n}} <span class="font-bold">„{{'Šablóny na stiahnutie' | i18n}}“</span>. {{'Stiahnite si anglickú alebo slovenskú verziu šablóny vo formátoch .docx alebo LaTeX.' | i18n}}
      </p>

      <p>
        {{'Celú ZP je potrebné napísať v predpísanej šablóne' | i18n}}.
      </p>

      <p>
        {{'Šablóny majú preddefinovaný správny rozmer papiera, okraje, fonty, štýly nadpisov a ďalšie štandardizované náležitosti.' | i18n}}
      </p>

      <p>
        {{'Písaním v šablóne sa vyhnete zbytočným komplikáciám pri registrácii ZP.' | i18n}}
      </p>

      <p>
        {{'ZP sa píše v štátnom, teda slovenskom jazyku. So súhlasom vedúceho garantujúceho pracoviska môže byť napísaná a obhajovaná aj v inom jazyku.' | i18n}}
      </p>

      <h4 [id]="sectionIdsEnum.zadanieZp">
        {{'3. Zadanie ZP / Zadávací list' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.zadanieZp)"></i>
      </h4>

      <p>
        {{'Zadanie ZP je dokument, ktorý obsahuje všetky potrebné náležitosti o záverečnej práci. Zadanie ZP vyplní školiteľ, resp. poverená osoba a vytlačí ho na zadávací list, ktorý podpíše dekan fakulty.' | i18n}}
      </p>

      <p>
        {{'Podpísaný ZL doručí Vaša fakulta do knižnice. V knižnici ZL oskenujeme a nahráme do' | i18n}}
        <a (click)="clickedEtdButton()">IS ETD</a>.
      </p>

      <p>
        {{'Po prihlásení si na domovskej stránke tento sken stiahnete vo formáte .jpeg a vložíte do súboru záverečnej práce.' | i18n}}
      </p>

      <p>
        {{'Obrázok skenu roztiahnite na celú stranu určenú na zadanie ZP, vzdialenosť od okraja musí byť minimálne 1 cm.' | i18n}}
      </p>

      <p>
        {{'Čestné prehlásenie v ZP nie je potrebné podpisovať perom, je podpísané elektronicky v rámci LZ.' | i18n}}
      </p>

      <h4 [id]="sectionIdsEnum.udajeZp">
        {{'4. Údaje o ZP a odklad sprístupnenia' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.udajeZp)"></i>
      </h4>

      <p>
        {{'Školiteľ zadá do' | i18n}} <a (click)="clickedMais()">IS MAIS</a> {{'údaje o ZP (názov, konzultanti, oponenti).' | i18n}}
      </p>

      <p>
        {{'Pre prípad, že text ZP obsahuje citlivé údaje tretích strán, napríklad firemné know-how a pod., školiteľ môže nastaviť v' | i18n}}
        <a (click)="clickedMais()">IS MAIS</a>
        {{'tzv. odklad sprístupnenia ZP ešte pred nahrávaním ZP študentom do ' | i18n}}
        <a (click)="clickedEtdButton()">IS ETD</a>.
        {{'(Po odoslaní práce do ' | i18n}}
        <a (click)="clickedOpacCrzp()">CRZP</a>
        {{'už odklad sprístupnenia nie je možný).' | i18n}}
      </p>

      <p>
        {{'Odklad sprístupnenia spôsobí, že plný text ZP v' | i18n}}
        <a (click)="clickedOpacCrzp()">CRZP</a>
        {{'bude 12, 24 alebo najviac 36 mesiacov neprístupný a zobrazovať sa bude len bibliografický záznam ZP, bližšie informácie viď bod 8.' | i18n}}
      </p>

      <h4 [id]="sectionIdsEnum.klucSlova">
        {{'5. Vyplnenie kľúčových slov a abstraktov v IS MAIS' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.klucSlova)"></i>
      </h4>

      <p>
        {{'Po napísaní práce sa prihláste do' | i18n}} <a (click)="clickedMais()">IS MAIS</a>.
      </p>

      <p>
        {{'Kliknite na záložku' | i18n}} <span class="font-bold">„{{'Záverečná práca' | i18n}}”</span>.
      </p>

      <p>
        {{'Vyplňte kľúčové slová a abstrakty v slovenskom i anglickom jazyku.' | i18n}}
      </p>

      <p>
        {{ 'V prípade, že v' | i18n }} <a (click)="clickedMais()">IS MAIS</a> {{'nie je uvedený oponent, kontaktujte vedúceho ZP.' | i18n }}
      </p>

      <h4 [id]="sectionIdsEnum.predbKontrola">
        {{'6. Predbežná kontrola originality' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.predbKontrola)"></i>
      </h4>

      <p>
        {{'Pred registráciou ZP ponúkame možnosť predbežnej kontroly originality.' | i18n}}
      </p>

      <p>
        {{'Služba je prevádzkovaná Technickou univerzitou v Košiciach a nájdete ju na adrese' | i18n}} <a
        (click)="clickedNiesomPlagiator()">https://niesomplagiator.sk/</a>.
      </p>

      <p>
        {{'Po registrácii a uzamknutí ZP už nebude možné obsah práce upraviť či zmeniť.' | i18n}}
      </p>

      <h4 [id]="sectionIdsEnum.pripravaUdajov">
        {{'7. Príprava údajov a súborov na registráciu ZP' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.pripravaUdajov)"></i>
      </h4>

      <p>
        {{'Pred registráciou si pripravte finálne súbory pre nahratie ZP do IS ETD:' | i18n}}
      </p>

      <ul>
        <li>{{ 'súbor v natívnom formáte podľa šablóny – .docx alebo LaTeX súbory v .zip' | i18n }}</li>
        <li>{{ 'súbor v .pdf formáte' | i18n }}</li>
        <li>{{ 'prílohy v spoločnom komprimovanom priečinku .zip.' | i18n }}</li>
      </ul>

      <div class="alert alert-warning text-center">

        <div class="text-center font-bold">{{ 'POZOR!' | i18n }}</div>

        {{'Finálny textový súbor v oboch formátoch musí byť zhodný s fyzickým výtlačkom ZP.' | i18n}}

      </div>

      <p>
        {{'Ak bude súčasťou jedného výtlačku aj používateľská príručka, systémová príručka a podobné A4 textové prílohy, mali by byť súčasťou aj finálneho textového súboru. V prípade mimoriadnych situácií pri veľkom objeme strán (napríklad zdrojové kódy) sa obráťte na školiteľa.' | i18n}}
      </p>

      <p>
        {{'Nadrozmerné formáty príloh (pôdorysy, plány, mapy a pod.), ktoré presahujú rozmer A4, sa ukladajú ako samostatné súbory a tlačia sa ako samostatné výtlačky.' | i18n}}
      </p>

      <p>
        {{'Na CD/DVD médium sa neskôr pri tlači umiestňujú:' | i18n}}
      </p>

      <ul>
        <li>{{'digitálna verzia ZP v natívnom a .pdf formáte' | i18n}}</li>
        <li>{{'samostatné súbory príloh v spoločnom komprimovanom priečinku .zip – dátové súbory, pôdorysy, plány, mapy, digitálne modely, kódy atď.' | i18n}}
        </li>
      </ul>

      <small>

        <p>
          {{'Ako uložiť dokument .docx vo formáte .pdf' | i18n}}
        </p>

        <p>
          {{'Nové verzie MS Word majú možnosť uložiť rozpracovaný súbor do formátu Adobe PDF. Inou možnosťou je použiť voľný program' | i18n}}
          <a (click)="clickedPdfCreator()">PDF Creator</a>,
          {{'ktorý po nainštalovaní pridá do počítača virtuálnu tlačiareň. PDF súbor následne „vytlačíte“ a umiestnite na disk.' | i18n}}
        </p>

        <p>
          {{'Ako vytvoriť komprimovaný priečinok .zip' | i18n}}
        </p>

        <p>
          {{'V priečinku označíte všetky súbory, ktoré chcete skomprimovať. Pravým tlačidlom na myši v ponuke vyberte Odoslať kam / Send to → Komprimovaný priečinok ZIP / Compressed (Zipped) Folder.' | i18n}}
        </p>

      </small>

      <p>
        {{'Pripravte si aj údaje o ZP:' | i18n}}
      </p>

      <ul>
        <li>
          {{'počet strán práce (posledné vytlačené číslo na hárku podľa stránkovania dokumentu)' | i18n}}
        </li>
        <li>
          {{'počet príloh (počet súborov príloh v spoločnom .zip priečinku, CD/DVD nosič sa ako príloha nepočíta)' | i18n}}
        </li>
        <li>
          {{'dátum zadania práce (zo ZL)' | i18n}}
        </li>
        <li>
          {{'dátum obhajoby práce' | i18n}}
        </li>
      </ul>

      <small>
        <p>
          {{'Ak nemáte žiadne prílohy a na CD/DVD sa nachádzajú len textové súbory s digitálnou verziou ZP – zvolíte NIE . Ak sú súčasťou ZP príručky, ktoré sú tlačené a viazané spolu s hlavnou časťou (a teda nemajú samostatné súbory) – zvolíte NIE.' | i18n}}
        </p>
        <p>
          {{'Ak presný dátum obhajoby nemáte určený, platí rozpätie približného dátumu podľa termínov fakulty.' | i18n}}
        </p>
      </small>

<!--      <div class="alert alert-warning">-->

<!--        <p class="color-green font-bold text-center margin-bottom-10">-->
<!--          Aký počet príloh mam zadať pri registrovaní práce?-->
<!--        </p>-->

<!--        <div class="attachments-images">-->

<!--          <div class="image-container">-->
<!--            <img src="/assets/img/prilohy0.jpg" alt="">-->
<!--            <span>Príklad č.1 zadania počtu príloh</span>-->
<!--          </div>-->

<!--          <div class="image-container">-->
<!--            <img src="/assets/img/prilohy2.jpg" alt="">-->
<!--            <span>Príklad č.2 zadania počtu príloh</span>-->
<!--          </div>-->

<!--        </div>-->

<!--      </div>-->

      <h4 [id]="sectionIdsEnum.registraciaEtd">
        {{'8. Registrácia súborov v IS ETD' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.registraciaEtd)"></i>
      </h4>

      <p>
        V <a (click)="clickedEtdButton()">IS ETD</a> {{ 'kliknite na záložku' | i18n }} <span class="font-bold">„{{'Registrácia ZP' | i18n}}“</span>.
      </p>

      <p>
        {{'Vyberte štúdium, pre ktoré nahrávate ZP.' | i18n}}
      </p>

      <p>
        {{'Skontrolujte si údaje načítané z' | i18n}} <a (click)="clickedMais()">IS MAIS</a> {{'a pokračujte na ďalší krok.' | i18n}}
      </p>

      <div class="alert alert-warning text-center">

        <div class="text-center font-bold">{{'POZOR!' | i18n}}</div>

        <div class="text-center">{{'Dbajte na správnosť údajov!' | i18n}}</div>

        <div class="text-center">{{'Pri zistení chýb budete kontaktovaní prostredníctvom @student.tuke.sk mailu.' | i18n}}</div>

        <div class="text-center">{{'ZP po uzamknutí nebude možné žiadnym spôsobom meniť.' | i18n}}</div>

      </div>

      <p>
        {{'Doplňte chýbajúce údaje:' | i18n}}
      </p>

      <ul>
        <li>{{'počet príloh' | i18n}}</li>
        <li>{{'počet strán' | i18n}}</li>
        <li>{{'dátum zadania' | i18n}}</li>
        <li>{{'dátum obhajoby' | i18n}}</li>
      </ul>

      {{'V ďalších krokoch nahraté súbory.' | i18n}}

      <div class="alert alert-warning text-center">

        <div class="text-center font-bold">{{'POZOR!' | i18n}}</div>

        <div class="text-center">{{'Uistite sa, že máte stabilné pripojenie na internet.' | i18n}}</div>

        <div class="text-center">{{'Ak sa súbory nahrávajú príliš dlho, je možné, že nastal problém v spojení.' | i18n}}</div>

      </div>

      <ul>
        <li>{{'nahrajte ZP v natívnom formáte (.docx alebo LaTeX)' | i18n}}</li>
        <li>{{'nahrajte ZP vo formáte .pdf' | i18n}}</li>
        <li>{{'nahrajte prílohy v spoločnom komprimovanom priečinku .zip' | i18n}}</li>
      </ul>

      <p>
        {{'Ak ZP neobsahuje prílohy, prejdite na ďalší krok, ktorým je sumarizácia.' | i18n}}
      </p>

      <p>
        {{'Po kontrole zosumarizovaných údajov označte súhlas so správnosťou a prácu zaregistrujte.' | i18n}}
      </p>

      <p>
        {{'Po úspešnej registrácii' | i18n}} <a (click)="clickedEtdButton()">IS ETD</a> {{'vygeneruje elektronicky podpísanú Licenčnú zmluvu.' | i18n}}
      </p>

      <p>
        {{'Pre prípad, že má ZP odklad sprístupnenia,' | i18n}}
        <a (click)="clickedEtdButton()">IS ETD</a>
        {{'vygeneruje aj Súhlas na odklad sprístupnenia práce. Súhlas podpísaný dekanom, vedúcim školiaceho pracoviska a školiteľom doručíte do knižnice. Bez doručeného súhlasu práca nebude uzamknutá.' | i18n}}
      </p>

      <h4 [id]="sectionIdsEnum.uzamknutie">
        {{'9. Uzamknutie práce' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.uzamknutie)"></i>
      </h4>

      <p>
        {{'V knižnici zaregistrovanú ZP do 5 pracovných dní uzamkneme.' | i18n}}
      </p>

      <p>
        {{'Uzamknutie značí odoslanie súborov na kontrolu originality do' | i18n}}
        <a (click)="clickedOpacCrzp()">CRZP</a>.
        {{'Školiteľovi a oponentom sú doručené linky na posudky k ZP, ktoré vyplnia.' | i18n}}
      </p>

      <p>
        {{'Po úspešnom uzamknutí si môžete v' | i18n}}
        <a (click)="clickedEtdButton()">IS ETD</a>
        {{'objednať tlač a viazanie ZP.' | i18n}}
      </p>

      <h4 [id]="sectionIdsEnum.protokol">
        {{'10. Protokol o kontrole originality' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.protokol)"></i>
      </h4>

      <p>
        {{'Po uzamknutí ZP vygeneruje' | i18n}}
        <a (click)="clickedOpacCrzp()">CRZP</a>
        {{'do 5 dní protokol o kontrole originality' | i18n}}.
      </p>

      <p>
        {{'Link na súbor s protokolom sa zobrazí v' | i18n}}
        <a (click)="clickedMais()">IS MAIS</a>
        {{'aj ' | i18n}}
        <a (click)="clickedEtdButton()">IS ETD</a>
        {{'do 48 hodín od vygenerovania protokolu' | i18n}}.
      </p>

      <h4 [id]="sectionIdsEnum.tlac">
        {{'11. Objednávka tlače a viazania ZP' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.tlac)"></i>
      </h4>

      <p>
        {{'Objednať si tlač a viazanie ZP je možné online v' | i18n}} <a (click)="clickedEtdButton()">IS ETD</a>
        {{'alebo osobne v Copycentre v knižnici počas prevádzkových hodín.' | i18n}}
      </p>

      <div class="alert alert-warning text-center">

        <div class="font-bold text-center">{{'POZOR!' | i18n}}</div>

        <div class="text-center">{{'Tlač a viazanie ZP je možné realizovať až po jej uzamknutí.' | i18n}}</div>

        <div class="text-center">{{'Žiadna časť práce nebude vyberateľná či zameniteľná.' | i18n}}</div>

      </div>

      <p>
        {{'V' | i18n}} <a (click)="clickedEtdButton()">IS ETD</a> {{'kliknite na záložku „Moje ZP“ a vyberte „Tlač ZP“.' | i18n}}
      </p>

      <p>
        {{'Nahrajte tlačový .pdf súbor.' | i18n}}
      </p>

      <p class="color-green">
        {{'Aký je rozdiel medzi tlačovým .pdf súborom a .pdf súborom z registrácie ZP?' | i18n}}
      </p>

      <p>
        {{'V prípade, že nemáte k ZP samostatné súbory príloh určené na tlač, nahrajte rovnaký súbor ako pri registrácii.' | i18n}}
      </p>

      <p>
        {{'V prípade, že vaša ZP obsahuje používateľskú, systémovú príručku alebo podobné časti vo formáte A4 určené na tlač – a pri registrácii ste ich nahrávali ako samostatné súbory príloh – vložte ich teraz do súboru s obsahom ZP, aby tvorili spoločný, ucelený .pdf súbor na tlač.' | i18n}}
      </p>

      <p>
        {{'K hotovému výtlačku podľa nahraného .pdf súboru potom vytvoríme sprievodné CD/DVD médium, na ktorom sa budú nachádzať súbory z registrácie ZP:' | i18n}}
      </p>

      <ul>
        <li>{{'ZP v natívnom formáte (.docx alebo LaTeX)' | i18n}}</li>
        <li>{{'ZP vo formáte .pdf' | i18n}}</li>
        <li>{{'súbory príloh v spoločnom komprimovanom priečinku .zip' | i18n}}</li>
      </ul>

      <p>
        {{'V prípade, že k vašej ZP potrebujete vytlačiť nadrozmerné prílohy väčšie než A4 (pôdorysy, mapy, plány a pod.), tieto prílohy netlačíme, ale napálime ich na sprievodné CD/DVD spolu s riadnymi prílohami z registrácie.' | i18n}}
      </p>

      <p>
        {{'Zvlášť pre študentov Stavebnej fakulty platí, že nadrozmerné tlačové prílohy tlačíme do osobitného záverečného boxu k ZP.' | i18n}}
      </p>

      <p>
        {{'CD s grafickou potlačou obsahujúcou údaje o ZP a autorovi bude vložené v papierovom obale vlepenom v zadnej časti práce.' | i18n}}
      </p>

      <p>
        {{'Výtlačky ZP majú na chrbte vytlačené meno autora a rok.' | i18n}}
      </p>

      <p>
        {{'Do online objednávky následne zadajte požadovaný počet výtlačkov ZP.' | i18n}}
      </p>

      <ul>
        <li>{{'1 výtlačok (s originálom zadávacieho listu) ostáva v knižnici na archiváciu podľa organizačnej smernice OS/TUKE/P1/02 Záverečné práce a kvalifikačné práce.' | i18n}}</li>
        <li>{{'Všetky ostatné výtlačky odošleme na poštovú adresu (v rámci územia SR) alebo pripravíme na osobný odber v Copycentre v knižnici. Pokiaľ je fakultou definovaný povinný výtlačok aj na štátne skúšky, tak druhý výtlačok ZP dodáme na fakultu za vás.' | i18n}}</li>
      </ul>

      <p>
        {{'Následne uhraďte objednávku platobnou kartou formou platobnej brány.' | i18n}}
      </p>

      <div class="alert alert-warning text-center">

        <div class="font-bold text-center">
          {{'POZOR!' | i18n}}
        </div>

        <div class="text-center">
          {{'Zmena počtu výtlačkov a zmena tlačového pdf súboru po úhrade poplatku nebude možná.' | i18n}}
        </div>

      </div>

      <h4 [id]="sectionIdsEnum.cennik">
        {{'12. Cenník viazania a tlače ZP v Copycentre' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.cennik)"></i>
      </h4>

      <p>
        <a (click)="clickedPricelist()">{{'Aktuálny cenník viazania a tlače ZP' | i18n}}</a>
      </p>

      <h4 [id]="sectionIdsEnum.crzp">
        {{'13. Sprístupnenie v CRZP' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.crzp)"></i>
      </h4>

      <p>
        {{'Záverečné práce budú sprístupnené v' | i18n}} <a (click)="clickedOpacCrzp()">CRZP</a> {{'do 90 dní od obhajoby ZP podľa rozsahu licencie uvedenej v LZ. Doba archivácie je 70 rokov.' | i18n}}
      </p>

      <h4 [id]="sectionIdsEnum.odovzdavanie">
        {{'14. Odovzdávanie výtlačkov ZP' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.odovzdavanie)"></i>
      </h4>

      <p>
        {{'Jeden výtlačok ZP odovzdávate do knižnice pre archiváciu.' | i18n}}
      </p>

      <p>
        {{'Ak ste si tlač a viazanie ZP objednali u nás, výtlačok na archiváciu ostáva automaticky v knižnici.' | i18n}}
      </p>

      <p>
        {{'V prípade, že ste prácu u nás netlačili, je potrebné sa u nás zastaviť po originál ZL.' | i18n}}
      </p>

      <p>
        {{'ZP s originálom ZL je potrebné do knižnice dodať do 5 pracovných dní.' | i18n}}
      </p>

      <p>
        {{'Po kontrole (originál ZL, povinné zlatené riadky, meno a rok na chrbte práce, označené CD v obale) vám zašleme potvrdenie o splnení si povinnosti odovzdania ZP do knižnice.' | i18n}}
      </p>

      <h4 [id]="sectionIdsEnum.archivacia">
        {{'15. Archivácia ZP v knižnici' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.archivacia)"></i>
      </h4>

      <p>
        {{'Výtlačok doručený na archiváciu do knižnice skatalogizujeme podľa bibliografických údajov z' | i18n}} <a (click)="clickedEtdButton()">IS ETD</a>
        {{ 'a zaradíme do' | i18n }}
        <a (click)="clickedOpac()">{{ 'knižničného fondu' | i18n }}</a>.
      </p>

      <p>
        {{'Archivovaný výtlačok nie je sprístupnený pre výpožičku, po dohode je možné sprístupniť ZP k nahliadnutiu. Doba archivácie je 70 rokov.' | i18n}}
      </p>

      <h4 [id]="sectionIdsEnum.prezentacie">
        {{'16. Prezentácie zo školenia ETD' | i18n}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.prezentacie)"></i>
      </h4>

      <p>
        <a (click)="downloadFile(filesEnum.electronicTheses)">{{'Elektronické záverečné práce na TU Košice' | i18n}}</a>
      </p>

      <p>
        <a (click)="downloadFile(filesEnum.quoteLiterature)">{{'Citovanie literatúry a tvorba zoznamu bibliografických odkazov' | i18n}}</a>
      </p>

      <h4 [id]="sectionIdsEnum.harmonogramOdovzdaniaZp">
        {{'17. Harmonogram odovzdávania ZP' | i18n}} {{currentYear}}
        <i class="fa fa-link float-copy-btn" (click)="copyQuestionUrl($event, sectionIdsEnum.harmonogramOdovzdaniaZp)"></i>
      </h4>

      <p>
        <img src="assets/img/custom/harmonogram_zp.png" alt="">
      </p>

    </div>

  </div>


</div>

<button class="btn float-btn" (click)="clickedEtdButton()">{{'Prihlásenie do IS ETD' | i18n}}</button>
