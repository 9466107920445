import { Component, OnInit } from '@angular/core';
import {BaseOverlayDialogComponent} from '../../base-overlay-dialog/base-overlay-dialog';

@Component({
  selector: 'app-borrow-termins-faq-dialog',
  templateUrl: './borrow-termins-faq-dialog.component.html',
  styleUrls: ['./borrow-termins-faq-dialog.component.scss']
})
export class BorrowTerminsFaqDialogComponent extends BaseOverlayDialogComponent implements OnInit {
  title: string;

  ngOnInit(): void {
  }

  initialize(
    title: string = null
  ) {
    this.title = title;
  }


}
