<!--=== Content Part ===-->
<div class="container content">
  <div class="title-box-v2">
    <h2>{{'O' | i18n}} <span class="color-green">{{'Univerzitnej Knižnici' | i18n}}</span></h2>
    <p>
      {{'Poslaním knižnice je informačné zabezpečenie vedeckého výskumu a pedagogického procesu na TUKE. Služby poskytujeme pedagógom, výskumníkom, ostatným zamestnancom TUKE, študentom TUKE.' | i18n}}
    </p>

    <p>
      {{'Naša web stránka umožňuje používateľom z TUKE objednávať a rezervovať si služby knižnice prostredníctvom elektronických formulárov, využívať elektronický systém zberu a sprístupňovania publikačnej činnosti TUKE a pristupovať k predplateným databázam a elektronickým informačným zdrojom, ktoré sú pre ostatných používateľov internetu nedostupné.' | i18n}}
    </p>

    <p>
      {{'Knižnica slúži aj ako vzdelávacie pracovisko v rozsahu svojej špecializácie a poskytuje pravidelné školenia knižnično-informačnej výchovy pre TUKE, ako aj pre záujemcov z iných pracovísk a knižníc na Slovensku.' | i18n}}
    </p>
  </div>

  <div class="shadow-wrapper margin-bottom-50">

    <p-carousel [value]="carouselItems">

      <ng-template let-item pTemplate="item">

        <div class="carousel-item">

          <img class="img-responsive" *ngIf="item?.backgroundImageUrl" [src]="item?.backgroundImageUrl">

        </div>

      </ng-template>
    </p-carousel>

  </div>

  <div class="title-box-v2">
    <p>
      {{'Knižničný fond UK TUKE tvoria jej vlastné fondy zložené z kníh, skrípt, zborníkov, časopisov, záverečných prác a elektronických médií. V roku 2022 knižničný fond tvorilo 172 878 knižničných jednotiek. Ročný prírastok do knižnice je vyše 3000 kj. V študovni sa nachádza základná a doplnková študijná literatúra, časopisy, zborníky, encyklopédie, jazykové a náučné slovníky, používatelia majú k nim voľný prístup. V rámci výpožičných služieb si používatelia ročne vypožičajú približne 5000 knižničných jednotiek.' | i18n}}
    </p>
    <p>
      {{'Knižnica umožňuje používateľom bezbariérový prístup prostredníctvom dvoch výťahov a dvoch bezbariérových toaliet. V študovni je k dispozícii 650 študijných miest. V posledných rokoch knižnica eviduje vysoký záujem o študijné miesta s elektrickou zásuvkou pre nabíjanie notebookov a mobilov; takýchto miest je zriadených vyše 300. V knižnici je naďalej aj 32 desktopových počítačov s pripojením na internet, intranet, Wi-Fi sieť a prístupom do databáz elektronických periodík.' | i18n}}
    </p>
    <p>
      {{'Prostredníctvom knižničného Copycentra majú študenti k dispozícii tlačiarenské a reprografické služby poskytované na 12 tlačiarňach, z toho 3 veľkokapacitných pre nadrozmernú tlač. Knižnica disponuje aj vlastným digitalizačným pracoviskom so skenovacím robotom, a tiež jednoduchým knižným skenerom pre verejnosť umiestneným v študovni. V súčasnosti sa knižničný fond buduje nielen na fyzickej úrovni (print a elektronické nosiče – USB, CD, DVD), ale rozvíja sa aj repozitár digitalizovaných kníh z knižničného fondu na platforme MediaINFO, obsahujúci vyše 600 titulov.' | i18n}}
    </p>
  </div>

  <!-- End About Sldier -->
  <div class="title-box-v2">
    <h2>{{'Služby' | i18n}} <span class="color-green">{{'Univerzitnej Knižnice' | i18n}}</span></h2>
  </div>

  <div class="row margin-bottom-5 services-container">
    <div class="col-sm-3 service-item" *ngFor="let service of services">
      <div class="service-block service-block-default">
        <i class="icon-custom rounded icon-color-dark" [ngClass]="service.iconClass"></i>
        <h2 class="heading-md">{{service.header | i18n}}</h2>
        <p>{{service.text | i18n}}</p>
        <ul class="list-unstyled">
          <li><i class="fa fa-phone"></i> {{service.phone}}</li>
        </ul>
      </div>
    </div>
  </div>

</div>
<!--=== End Content Part ===-->
<!--=== Contacts ===-->
<div class="service-block-v4">
  <div class="container content" style="padding-bottom:20px !important;">
    <div class="title-box-v2">
      <h2>{{'Naše' | i18n}} <span class="color-green">{{'Kontakty' | i18n}}</span></h2>
    </div>

    <div class="row">
      <div class="col-md-9 col-sm-9">
        <!-- Google Map -->
        <div id="map" class="map">
          <iframe
            [src]="contact.mapUrl | safeUrl"
            width="100%" height="350" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-md-3 col-sm-3">
        <div class="headline"><h2>{{'Kontakt' | i18n}}</h2></div>
        <ul class="list-unstyled who margin-bottom-30">
          <li><a href="#"><i class="fa fa-home"></i>{{contact?.title}}</a></li>
          <li><a href="#"><i class="fa fa-home"></i>{{contact?.address}}</a></li>
          <li><a href="#"><i class="fa fa-envelope"></i>{{contact.mail}}</a></li>
          <li><a href="#"><i class="fa fa-phone"></i>{{contact.phone}}</a></li>
          <li><a href="#"><i class="fa fa-globe"></i>{{contact.web}}</a></li>
        </ul>
        <div class="headline"><h2>{{'GPS súradnice' | i18n}}</h2></div>
        <ul class="list-unstyled who margin-bottom-30">
          <li><a href="#"><i class="fa fa-car"></i>{{contact.gpsFull}}</a></li>
          <li><a href="#"><i class="fa fa-car"></i>{{contact.gps}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="parallax-team parallaxBg">
  <div class="container content">
    <div class="title-box-v2">
      <h2>{{'Vedenie' | i18n}} <span class="color-green">{{'Univerzitnej knižnice' | i18n}}</span></h2>
    </div>
    <div class="row equal">
      <!-- Team v2 -->
      <div class="col-md-3 col-sm-6 col-xs-12" *ngFor="let item of stuff">
        <div class="team-v2">
          <div class="inner-team">
            <h3>{{item?.name}}</h3>
            <small class="color-green">{{item?.role | i18n}}</small>
            <p>{{item?.fullRole | i18n}}</p>
            <hr>
            <ul class="list-unstyled">
              <li *ngFor="let subitem of item.items"><i class="fa" [ngClass]="subitem.icon"></i> {{subitem?.text}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="contacts content">
  <div class="container">
    <div class="title-box-v2">
      <h2>{{'Otváracie' | i18n}} <span class="color-green">{{'Hodiny' | i18n}}</span></h2>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-3 col-lg-3" *ngFor="let hour of openingHours">
        <div class="headline"><h2>{{hour.sectionTitle}}</h2></div>
        <div class="row" *ngFor="let data of hour.sectionData">
            <div class="col-md-2 col-sm-4 col-xs-2">
              <strong>{{data.day | i18n}}</strong>
            </div>
            <div class="col-md-10 col-sm-8 col-xs-10">
              {{data.hours | i18n}}
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
